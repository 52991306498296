<template>
  <div>
    <div class="space-query">
      <long-range-search-game ref="longSearch" :game="game" @select="handleSelect"></long-range-search-game>
      <el-select v-model.trim="queryField.searchType" placeholder="类型">
        <el-option label="全部" value=""></el-option>
        <el-option label="搜索框滚动" value="1"></el-option>
        <el-option label="游戏热搜" value="2"></el-option>
      </el-select>
      <el-select v-model.trim="queryField.status" placeholder="状态">
        <el-option label="全部" value=""></el-option>
        <el-option label="正常" value="1"></el-option>
        <el-option label="下架" value="0"></el-option>
      </el-select>

      <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
      <el-button type="warning" size="small" @click="addGameSearch">添加</el-button>
    </div>

    <div style="margin-top: 10px">
      <el-table
          class="space-table"
          :data="tableData"
          :height="height"
          border
          style="width: 100%">
        <el-table-column
            prop="game_name"
            label="游戏名称"
            width="120">
        </el-table-column>
        <el-table-column
            prop="search_type"
            label="类型"
            :formatter="formatterType"
            width="120">
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            :formatter="formatterStatus"
            width="80">
          <template v-slot="scope">
            <span :style="{color: scope.row.status === 1 ? '#67C23A' : '#F56C6C'}">
              {{ scope.row.status === 1 ? '正常' : '下架'}}
            </span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            width="180">
          <template v-slot="scope">
            <el-button type="text" size="small" @click="onClickEdit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          class="space-table-data"
          background
          layout="total, prev, pager, next"
          :page-size="pageSize"
          hide-on-single-page
          @current-change="loadData"
          :current-page="currentPage"
          :total="total">
      </el-pagination>

      <el-drawer
          :visible.sync="drawer"
          direction="rtl"
          :before-close="handleClose">
        <div class="space-drawer">
          <el-form ref="addFormRule" :model="formRule" :rules="rules">
            <el-form-item prop="gameId">
              <label slot="label">游戏名称</label>
              <long-range-search-game ref="longSearchAdd" :game="addGame" @select="handleAddSelect"></long-range-search-game>
            </el-form-item>
            <el-form-item prop="searchType">
              <label slot="label">类型</label>
              <el-select v-model.trim="formRule.searchType" placeholder="类型">
                <el-option label="搜索框滚动" value="1"></el-option>
                <el-option label="游戏热搜" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="status">
              <label slot="label">状态</label>
              <el-select v-model.trim="formRule.status" placeholder="状态">
                <el-option label="正常" value="1"></el-option>
                <el-option label="下架" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('addFormRule')">提交</el-button>
              <el-button @click="resetForm('addFormRule')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-drawer>
      
      <el-drawer
          :visible.sync="editDrawer"
          direction="rtl">
        <div class="space-drawer">
          <el-form ref="editFormRule" :model="editFormRule">
            <el-form-item >
              <label slot="label">游戏名称</label>
              <el-input v-model="editFormRule.game_name" disabled />
            </el-form-item>
            <el-form-item>
              <label slot="label">类型</label>
              <el-select v-model.trim="editFormRule.search_type" placeholder="类型">
                <el-option label="搜索框滚动" :value="1"></el-option>
                <el-option label="游戏热搜" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <label slot="label">状态</label>
              <el-select v-model.trim="editFormRule.status" placeholder="状态">
                <el-option label="正常" :value="1"></el-option>
                <el-option label="下架" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="save()">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {addGameSearch, getGameSearch, saveGameSearch} from "@/api/request";
import LongRangeSearchGame from "@/components/LongRangeSearchGame.vue";

export default {
  name: "GameSearch",
  components: {LongRangeSearchGame},
  data(){
    return {
      queryField: {
        gameId: '',
        searchType: '',
        status: '',
      },
      game: '',
      tableData: [],
      height: window.innerHeight - 210,
      total: 0,
      currentPage: 1,
      pageSize: 15,
      restaurants: [],
      drawer: false,
      formRule: {
        gameId: '',
        searchType: '',
        status: '1'
      },
      rules: {
        gameId: [
          { required: true, message: '请填写游戏名称', trigger: 'blur' }
        ],
        searchType: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ],
      },
      addGame: '',
      editDrawer: false,
      editFormRule: {},
    }
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      getGameSearch(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentPage = index;
      })
    },
    submitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addGameSearch(this.formRule).then(res => {
            if(res.data.success){
              this.$message.success("添加成功");
              this.resetForm('addFormRule');
              this.loadData(1);
            }else {
              this.$message.error(res.data.error.message);
            }
          })
        }
      })
    },
    save(){
      saveGameSearch(this.editFormRule).then(res => {
        if(res.data.success){
          this.$message.success("保存成功");
          this.editDrawer = false;
          this.loadData(1);
        }else {
          this.$message.error(res.data.error.message);
        }
      })
    },
    addGameSearch(){
      this.drawer = true;
    },
    handleSelect(item){
      this.queryField.gameId = item.gameId;
    },
    handleAddSelect(item){
      this.formRule.gameId = item.gameId;
      this.$refs.addFormRule.validateField("gameId", e => {})
    },
    handleClose(done){
      this.$refs.addFormRule.resetFields();
      done();
    },
    onClickEdit(item){
      this.editDrawer = true;
      this.editFormRule = item;
    },
    reset(){
      this.queryField = {
        gameId: '',
        searchType: '',
        status: '',
      }
      this.game = '';
      this.$refs.longSearch.reset();
    },
    resetForm(formName){
      this.$refs[formName].resetFields();
      this.$refs.longSearchAdd.reset();
    },
    formatterStatus(row, column, cellValue, index){
      let rep = "";
      if(cellValue === 1){
        rep = "正常";
      }else if(cellValue === 0){
        rep = "下架";
      }
      return rep;
    },
    formatterType(row, column, cellValue, index){
      let rep = "";
      if(cellValue === 1){
        rep = "搜索框滚动";
      }else if(cellValue === 2){
        rep = "热搜游戏";
      }
      return rep;
    }
  }
}
</script>

<style scoped>
::v-deep .el-form-item__label label {
  text-align-last: justify;
  width: 60px;
  display: inline-block;
}
</style>