<template>
  <div>
    <div class="space-query">
      <el-input v-model.trim="queryField.gameId" placeholder="游戏id"></el-input>
      <el-input v-model.trim="queryField.gameName" placeholder="游戏名称"></el-input>
      <el-select v-model="queryField.gameStatus" placeholder="游戏状态">
        <el-option
            v-for="item in gameStatusOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="queryField.gameSystem" placeholder="游戏系统">
        <el-option
            v-for="item in gameSystemOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>

      <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
      <el-button type="warning" size="small" @click="addGame">添加游戏</el-button>
    </div>
    
    <div style="margin-top: 10px">
      <el-table
          class="space-table"
          :data="tableData"
          :height="height"
          border
          style="width: 100%">
        <el-table-column
            prop="game_id"
            label="游戏id"
            width="80">
        </el-table-column>
        <el-table-column
            prop="game_name"
            label="游戏名称"
            width="120">
        </el-table-column>
        <el-table-column
            label="游戏图标"
            width="80">
          <template v-slot="scope">
            <el-image
                style="width: 30px; height: 30px;font-size: 12px"
                :src="scope.row.game_icon"
                fit="fill" />
          </template>
        </el-table-column>
        <el-table-column
            prop="game_of"
            label="游戏厂商"
            width="100">
        </el-table-column>
        <el-table-column
            label="游戏状态"
            width="100">
          <template v-slot="scope">
            <span :style="{color: (statusFormatterColor(scope.row.game_status))}"> {{ statusFormatter(scope.row.game_status) }} </span>
          </template>
        </el-table-column>
        <el-table-column
            prop="game_type"
            label="游戏类型"
            :formatter="typeFormatter"
            width="100">
        </el-table-column>
        <el-table-column
            label="游戏系统"
            width="100">
          <template v-slot="scope">
            <el-image
                v-if="scope.row.game_system === '1' || scope.row.game_system === '3'"
                style="width: 20px; height: 20px"
                :src="require('@/assets/android.png')"
                fit="fill" />
            <el-image
                v-if="scope.row.game_system === '2' || scope.row.game_system === '3'"
                style="width: 20px; height: 20px"
                :src="require('@/assets/ios.png')"
                fit="fill" />
          </template>
        </el-table-column>
        <el-table-column
            width="400"
            label="操作">
          <template v-slot="scope">
            <el-button type="text" size="small" @click="$router.replace(`/game/edit/${scope.row.game_id}`)">编辑</el-button>
            <el-button type="text" size="small" @click="toUploadLogo(scope.row.game_id)">logo上传</el-button>
            <el-button type="text" size="small" @click="toUploadPackage(scope.row.game_id,scope.row.game_name)">包上传</el-button>
            <el-button type="text" size="small" @click="toRefreshCDN(scope.row.game_id)">刷新cdn</el-button>
            <el-button v-if="scope.row.game_type === '1' && scope.row.game_status === '2'" type="text" size="small" @click="toPack(scope.row.game_id)">打包</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          class="space-table-data"
          background
          layout="total, prev, pager, next"
          :page-size="pageSize"
          hide-on-single-page
          @current-change="loadData"
          :current-page="currentPage"
          :total="total">
      </el-pagination>
    </div>
    
    <!--  添加游戏  -->
    <el-drawer
        title="添加游戏"
        :visible.sync="isDrawer"
        direction="rtl">
      <div class="space-drawer">
        <el-form ref="formRule" :model="formRule" :rules="rules">
          <el-form-item label="游戏名称" prop="gameName">
            <el-input v-model.trim="formRule.gameName"></el-input>
          </el-form-item>
          <el-form-item label="游戏简称" prop="gameShortName">
            <el-input v-model.trim="formRule.gameShortName" placeholder="游戏名拼音简称"></el-input>
          </el-form-item>
          <el-form-item label="游戏厂商" prop="gameOf">
            <el-input v-model.trim="formRule.gameOf"></el-input>
          </el-form-item>
          <el-form-item label="游戏类型" prop="gameType">
            <el-select v-model="formRule.gameType">
              <el-option label="H5" value="1"></el-option>
              <el-option label="手游" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="formRule.gameType === '2'" label="游戏系统">
            <label slot="label">&nbsp;&nbsp;游戏系统</label>
            <el-select v-model="formRule.gameSystem" placeholder="游戏系统">
              <el-option label="安卓" value="1"></el-option>
              <el-option label="IOS" value="2"></el-option>
              <el-option label="双端" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="游戏状态" prop="gameStatus">
            <el-select v-model="formRule.gameStatus" placeholder="游戏状态">
              <el-option label="调试" value="1"></el-option>
              <el-option label="上线" value="2"></el-option>
              <el-option label="下线" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="游戏类别" prop="gameTopic">
            <el-select v-model="formRule.gameTopic">
              <el-option v-for="item in gameTopicData" :key="item.topic_id" :label="item.topic_name" :value="item.topic_id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('formRule')">提交</el-button>
            <el-button @click="resetForm('formRule')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    
    <!--  logo上传  -->
    <el-dialog
        title="logo上传"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="21%">
      <el-upload
          drag
          :data="param"
          action="#"
          accept=".jpg, .jpeg, .png"
          :show-file-list="false"
          :http-request="logoRequest"
          :before-upload="beforeUploadIcon">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传jpg/png/jpeg文件，且不超过2MB</div>
      </el-upload>
    </el-dialog>
    <el-dialog
        title="包上传"
        :visible.sync="dialogVisible2"
        :close-on-click-modal="false"
        width="21%">
      <el-upload
          drag
          :data="param"
          action="#"
          accept=".apk, .ipa"
          :show-file-list="false"
          :http-request="packageRequest"
          :before-upload="beforeUploadPackage">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">上传文件只能是 ipa或者apk 格式，且不超过5GB</div>
      </el-upload>
      <el-progress :percentage="param.progress"></el-progress>
    </el-dialog>
  </div>
</template>

<script>
import {
  addGame, packWd,
  queryGame,
  queryGameTopic,
  refreshCDN, saveGameSize,
  saveLogo,
  upload,
  uploadLogo,
  uploadPackage
} from "@/api/request";

export default {
  name: "GameList",
  data(){
    return {
      tableData:[],
      height: window.innerHeight - 210,
      total: 0,
      currentPage: 1,
      pageSize: 13,
      queryField: {
        gameId: '',
        gameName: '',
        gameSystem: '',
        gameStatus: ''
      },
      game: '',
      restaurants: [],
      gameStatusOption: [
        {label: "所有",value: ""},
        {label: "调试",value: "1"},
        {label: "上线",value: "2"},
        {label: "下线",value: "3"},
      ],
      gameSystemOption: [
        {label: "所有",value: ""},
        {label: "安卓",value: "1"},
        {label: "IOS",value: "2"},
        {label: "双端",value: "3"},
      ],
      isDrawer: false,
      formRule: {
        gameName: '',
        gameType: '',
        gameSystem: '',
        gameStatus: '',
        gameTopic: '',
        gameOf: '',
        gameShortName: ''
      },
      rules: {
        gameName: [
          { required: true, message: '请填写游戏名称', trigger: 'blur' }
        ],
        gameType: [
          { required: true, message: '请选择游戏类型', trigger: 'blur' }
        ],
        gameStatus: [
          { required: true, message: '请选择游戏状态', trigger: 'blur' }
        ],
        gameTopic: [
          { required: true, message: '请选择游戏类别', trigger: 'blur' }
        ],
        gameOf: [
          { required: true, message: '请填写游戏厂商', trigger: 'blur' }
        ],
        gameShortName: [
          { required: true, message: '请填写游戏简称', trigger: 'blur' }
        ]
      },
      param: {
        gameId: "",
        gameName: "",
        progress: 0,
      },
      dialogVisible: false,
      dialogVisible2: false,
      gameTopicData: [],
    }
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      queryGame(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentPage = index;
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addGame(this.formRule).then(res => {
            if(res.data.success){
              this.$confirm("添加成功，是否继续添加？",{
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success"
              }).then(() => {
              }).catch(() => {
                this.isDrawer = false;
                this.loadData(1);
                this.resetForm('formRule');
              })
            }else {
              this.$message.error(res.data.error.message);
            }
          })
        } else {
          this.$message.warning("请填写必填项")
          return false;
        }
      });
    },
    toRefreshCDN(gameId){
      refreshCDN(gameId).then(res => {
        if(res.data.success){
          this.$message.success("刷新成功");
        }else {
          this.$message.error(res.data.error.message)
        }
      })
    },
    toPack(gameId){
      packWd(gameId).then(res => {
        if(res.data.success){
          this.$message.success("打包成功，请等待5-10分钟");
        }else {
          this.$message.error(res.data.error.message)
        }
      })
    },
    toUploadLogo(gameId){
      this.param.gameId = gameId;
      this.dialogVisible = true;
    },
    addGame(){
      this.isDrawer = true;
      this.getGameTopic();
    },
    async logoRequest(item){
      // await uploadLogo(item).then(res => {
      //   if(res.success){
      //     saveLogo(item.file.name,item.data.gameId).then(res => {
      //       if(res.data.success){
      //         this.dialogVisible = false;
      //         this.$message.success("logo上传成功");
      //       }else {
      //         this.$message.error(res.data.error.message);
      //       }
      //     })
      //   }else {
      //     this.$message.error(res.error.message);
      //   }
      // });
      
      await upload(item,false,param => "image/" + param.name + "/logo").then(res => {
        if(res.success){
          saveLogo(item.file.name,item.data.gameId).then(res => {
            if(res.data.success){
              this.dialogVisible = false;
              this.$message.success("logo上传成功");
            }else {
              this.$message.error(res.data.error.message);
            }
          })
        }else {
          this.$message.error(res.error.message);
        }
      });
    },
    getGameTopic(){
      queryGameTopic().then(res => {
        this.gameTopicData = res.data.data;
      })
    },
    beforeUploadIcon(file) {
      const isJPEG = file.type === "image/jpeg";
      const isJPG = file.type === "image/jpg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!(isJPG || isPNG || isJPEG)) {
        this.$message.error("上传图片只能是JPG、PNG、JPEG格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      let filename = file.name;
      let pos = filename.lastIndexOf(".");
      let suffix = "";
      if (pos !== -1) suffix = filename.substring(pos);
      return (isJPG || isPNG) && isLt2M;
    },
    toUploadPackage(gameId, gameName){
      this.param.gameId = gameId;
      this.param.gameName = gameName;
      this.dialogVisible2 = true;
    },
    async packageRequest(item){
      // await uploadPackage(item).then(res => {
      //   if(res.success){
      //     this.$message.success("包上传成功");
      //     this.dialogVisible2 = false;
      //   }else {
      //     this.$message.error(res.error.message);
      //   }
      // }).finally(() => {
      //   this.param.progress = 0;
      // })
      await upload(item,true,param => param.name + "/" + item.data.gameName).then(res => {
        if(res.success){
          saveGameSize(item.data.gameId, item.file.size);
          this.$message.success("包上传成功");
          this.dialogVisible2 = false;
        }else {
          this.$message.error(res.error.message);
        }
      }).finally(() => {
        this.param.progress = 0;
      })
    },
    beforeUploadPackage(file){
      let filename = file.name;
      let pos = filename.lastIndexOf(".");
      let suffix = "";
      if (pos !== -1) suffix = filename.substring(pos);
      let isApp = ".ipa" === suffix || ".apk" === suffix;
      const isLt5G = file.size / 1024 / 1024 / 1024 < 5;
      if (!isApp) {
        this.$message.error("上传文件只能是 ipa或者apk 格式!");
      }
      if (!isLt5G) {
        this.$message.error("上传文件大小不能超过 5GB!");
      }
      return isApp && isLt5G;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.formRule.gameSystem = '';
    },
    querySearch(queryString, callback){
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      callback(results);
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item){
      this.queryField.gameName = item;
    },
    reset(){
      this.queryField = {
        gameId: '',
        gameName: '',
        gameSystem: '',
        gameStatus: ''
      }
    },
    statusFormatter(cellValue){
      let rep = "";
      if(cellValue === "1"){
        rep = "调试";
      }else if(cellValue === "2"){
        rep = "上线";
      }else if(cellValue === "3"){
        rep = "下线"
      }
      return rep;
    },
    statusFormatterColor(cellValue){
      let rep = "";
      if(cellValue === "1"){
        rep = "#E6A23C";
      }else if(cellValue === "2"){
        rep = "#67C23A";
      }else if(cellValue === "3"){
        rep = "#F56C6C"
      }
      return rep;
    },
    typeFormatter(row, column, cellValue, index){
      let rep = "";
      if(cellValue === "1"){
        rep = "H5";
      }else if(cellValue === "2"){
        rep = "手游";
      }
      return rep;
    }
  }
}
</script>

<style scoped>
  .space-query {
    width: 100%;
  }
  .space-table-data{
    float: left;
    margin: 10px 0;
  }
  .space-table {
    text-align: center;
  }

  /deep/ .el-table thead{
    color: #252525;
  }
  /deep/ .el-table .el-table__cell{
    padding: 8px 0;
    text-align: center;
  }
  
  .el-button{
    margin: 5px;
  }
  .el-button+.el-button{
    margin: 5px;
  }

  /deep/ .el-image__error{
    font-size: 12px;
  }
</style>