<template>
  <div>
    <div class="main-border">
    </div>
  </div>
</template>

<script>
export default {
  name: "welcome"
}
</script>

<style scoped>
.main-border{
  width: 100%;
  height: calc(100vh - 96px);
  background: url("../../assets/homeBg.jpg") no-repeat fixed;
  background-size: 100% 100%;
  border-radius: 10px;
  filter: contrast(90%) saturate(80%);
}
</style>