<template>
  <div>
    <div class="space-query">
      <el-input v-model.trim="queryField.configId" size="small" placeholder="配置id"></el-input>
      <el-autocomplete
          v-model.trim="game"
          size="small"
          :fetch-suggestions="querySearch"
          placeholder="游戏名称"
          @select="handleSelect"
          @input="handleGameInput"
      ></el-autocomplete>
      <el-button @click="loadData(1)" type="primary" size="small">查询</el-button>
      <el-button @click="reset" size="small">重置</el-button>
    </div>

    <el-table
        class="space-table"
        :data="tableData"
        :height="height"
        border
        style="width: 100%">
      <el-table-column
          prop="config_id"
          label="配置id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="game_name"
          label="游戏名称"
          width="120">
      </el-table-column>
      <el-table-column
          label="首冲金额"
          width="100">
        <template v-slot="scope">
          <el-input size="small" v-model="tableData[scope.$index].first_money" />
        </template>
      </el-table-column>
      <el-table-column
          label="初始提成"
          width="100">
        <template v-slot="scope">
          <el-input size="small" v-model="tableData[scope.$index].init_share" />
        </template>
      </el-table-column>
      <el-table-column
          label="首冲提成"
          width="100">
        <template v-slot="scope">
          <el-input size="small" v-model="tableData[scope.$index].first_share" />
        </template>
      </el-table-column>
      <el-table-column
          label="续充提成"
          width="100">
        <template v-slot="scope">
          <el-input size="small" v-model="tableData[scope.$index].continue_share" />
        </template>
      </el-table-column>
      <el-table-column
          label="用户自助折扣"
          width="110">
        <template v-slot="scope">
          <el-input size="small" v-model="tableData[scope.$index].user_auto" />
        </template>
      </el-table-column>
      <el-table-column
          label="注册开关"
          width="140">
        <template v-slot="scope">
          <el-switch
              style="display: block"
              v-model="tableData[scope.$index].register_status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="开启"
              inactive-text="关闭"
              :active-value="1"
              :inactive-value="2">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
          width="120">
        <template v-slot="scope">
          <el-button type="text" size="small" @click="saveGameConfig(scope.row)">保存</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {getGame, queryGameConfig, saveGameConfig} from "@/api/request";

export default {
  name: "GameConfig",
  data(){
    return {
      tableData: [],
      height: window.innerHeight - 210,
      total: 0,
      currenPage: 1,
      pageSize: 13,
      queryField:{
        gameId: '',
        configId: ''
      },
      game: '',
      restaurants: [],
    }
  },
  mounted() {
    this.loadGame();
    this.loadData(1);
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      queryGameConfig(params).then(res => {
        this.tableData = res.data.list;
        this.currenPage = index;
        this.total = res.data.total;
      })
    },
    saveGameConfig(row){
      saveGameConfig(row).then(res => {
        if(res.data.success){
          this.$message.success("保存成功");
        }else {
          this.$message.error(res.data.error.message);
        }
      })
    },
    loadGame() {
      getGame().then(res => {
        this.restaurants = [];
        res.data.data.forEach(e => {
          let item = {};
          item.value = e.gameName;
          item.gameId = e.gameId;
          this.restaurants.push(item);
        })
      })
    },
    querySearch(queryString, callback){
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      callback(results);
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item){
      this.queryField.gameId = item.gameId;
    },
    handleGameInput(value){
      const find = this.restaurants.find(e => e.value === this.game.trim());
      if(find){
        this.queryField.gameId = find.gameId;
      }else {
        this.queryField.gameId = '';
      }
    },
    reset(){
      this.queryField = {
          gameId: '',
          configId: ''
      }
      this.loadData(1);
    }
  }
}
</script>

<style scoped>

</style>