<template>
  <el-container class="space-container">
    <el-aside class="space-aside" :style="isCollapse ? 'width: 65px;' : 'width: 200px'">
      <div class="space-collapse" @click="isCollapse = !isCollapse">
        <i class="el-icon-s-fold" v-if="!isCollapse"/>
        <i class="el-icon-s-unfold" v-else/>
      </div>
      <el-menu 
          :default-active="$route.path"
          :router="true"
          :unique-opened="true"
          background-color="#2c3e50"
          active-text-color="#ffd04b"
          text-color="#fff"
          :collapse="isCollapse"
          style="width: 100%">
        <el-menu-item index="/home">
          <i class="el-icon-menu"></i>
          <span slot="title">首页</span>
        </el-menu-item>

        <el-submenu v-for="item in menuData" :index="item.menu_id + ''" :key="item.menu_id">
          <template slot="title">
            <i :class="item.icon_class"></i>
            <span slot="title">{{ item.menu_name }}</span>
          </template>
          <el-menu-item v-for="child in item.childList" :index="child.path" :key="child.menu_id">{{ child.menu_name }}</el-menu-item>
        </el-submenu>
        
      </el-menu>
    </el-aside>
    <el-container>
      <el-header class="space-header">
        <el-dropdown trigger="click" class="space-dropdown" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ nickname }}
            <i class="el-icon--right el-icon-arrow-down"/>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in dropdownOption" 
                              :icon="item.icon" 
                              :command="item.command" 
                              :key="item.command">
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>
      <el-main style="padding: 10px">
        <keep-alive>
          <transition name="el-fade-in-linear" mode="out-in">
            <router-view :key="$route.fullPath"/>
          </transition>
        </keep-alive>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {getMenu, logout} from "@/api/request"
export default {
  name: "Menu",
  data() {
    return {
      isCollapse: false,
      menuData: [],
      nickname: '',
      dropdownOption:[
        {label: "修改登录密码", command: '/changePassword',icon: "el-icon-key"},
        {label: "退出登录", command: '/logout',icon: "el-icon-switch-button"}
      ],
    }
  },
  mounted() {
    this.loadMenu();
    this.nickname = JSON.parse(localStorage.getItem("userInfo")).nickname;
  },
  methods: {
    loadMenu(){
      getMenu().then(res => {
        this.menuData = res.data.data;
      })
    },
    handleCommand(e){
      if("/logout" === e){
        this.doLogout();
      }else {
        this.$router.push(e);
      }
    },
    doLogout(){
      logout().then(() => {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("token");
        location.href = "/login";
        this.$message.success("退出成功")
      })
    }
  }
}
</script>

<style>
  .space-container{
    height: calc(100vh - 16px);
    overflow: hidden;
    border-radius: 10px;
  }

  .space-header{
    width: 100%;
    height: 60px !important;
    line-height: 60px;
    background-color: #3399CC;
    text-align: center;
  }
  
  .space-aside{
    background-color: #2c3e50;
    overflow: hidden;
    transition: all;
    transition-duration: 500ms;
  }
  
  .el-menu {
    border: 0 !important;
  }
  /deep/ .el-menu-item {
    color: #fff;
    font-size: 16px;
  }
  
  /deep/ .el-submenu__title{
    color: #fff;
    font-size: 16px;
  }
  
  .space-collapse {
    width: 100%;
    height: 56px;
    line-height: 56px;
    font-size: 35px;
    color: #fff;
    text-align: center;
    cursor: pointer;
  }
  
  .space-dropdown{
    user-select: none;
    cursor: pointer;
    float: right;
    color: #fff !important;
    font-size: 18px !important;
  }

  .el-range-editor{
    margin: 0 5px;  
  }
  
  .space-query {
    width: 100%;
    /*height: 20%;*/
  }
  .space-query .el-input,.space-query .el-autocomplete,.space-query .el-select {
    width: 130px;
    margin: 3px;
  }

  .space-query .el-input__inner {
    height: 35px;
  }
  .space-query .el-button,.space-query .el-button+.el-button{
    margin: 5px;
  }

  .space-table{
    text-align: center;
  }
  .space-table thead{
    color: #252525 !important;
  }
  .space-table .el-table__cell{
    padding: 8px 0 !important;
    text-align: center !important;
  }

  .space-table .el-button--text{
    padding: 0;
  }

  .space-drawer{
    margin: 0 40px;
  }

  .space-drawer .el-input,
  .space-drawer .el-textarea,
  .space-drawer .el-autocomplete {
    width: 300px;
    margin: 3px 0;
  }
  .space-drawer .el-select {
    width: 300px;
    margin: 0;
  }
  
  .space-drawer .el-button,
  .space-drawer .el-button+.el-button{
    margin: 3px;
  }
  
</style>