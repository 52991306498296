<template>
  <div>
    <div class="space-query">
      <el-input size="small" placeholder="配置id" />
      <el-autocomplete
          v-model.trim="org"
          size="small"
          :fetch-suggestions="querySearch2"
          placeholder="家族名称"
          @select="handleSelect2"
          @input="handleGameInput2"
      ></el-autocomplete>
      <el-autocomplete
          v-model.trim="game"
          size="small"
          :fetch-suggestions="querySearch"
          placeholder="游戏名称"
          @select="handleSelect"
          @input="handleGameInput"
      ></el-autocomplete>
      <el-button size="small" type="primary" @click="loadData(1)">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
    </div>
    <el-table
        class="space-table"
        :data="tableData"
        :height="height"
        border
        style="width: 100%">
      <el-table-column
        prop="config_id"
        label="配置id"
        width="80">
      </el-table-column>
      <el-table-column
        prop="org_name"
        width="120"
        label="家族名称">
      </el-table-column>
      <el-table-column
        prop="game_name"
        width="120"
        label="游戏名称">
      </el-table-column>
      <el-table-column
        width="100"
        label="家族分成">
        <template v-slot="scope">
          <el-input size="small" v-model="tableData[scope.$index].org_share" />
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="150">
        <template v-slot="scope">
          <el-button type="text" size="small" @click="saveShare(scope.row)">保存</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {getGame, getOrg, getOrgConfig, updateOrgShare} from "@/api/request";

export default {
  name: "OrgConfig",
  data() {
    return {
      tableData: [],
      height: window.innerHeight - 210,
      total: 0,
      currenPage: 1,
      pageSize: 13,
      queryField:{
        gameId: '',
        orgId: '',
        configId: ''
      },
      game: '',
      org: '',
      restaurants: [],
      restaurants2: [],
    }
  },
  mounted() {
    this.loadData(1);
    this.loadGame();
    this.loadOrg();
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      getOrgConfig(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currenPage = index;
      })
    },
    saveShare(row){
      const reg = /^[01]([.][0-9]{0,4})?$/;
      if (!reg.test(row.org_share + "")) {
        this.$message.error("分成格式不正确");
        return ;
      }
      if(row.org_share < 0 || row.org_share > 1){
        this.$message.error('分成比例应设置在0~1.0之间');
        return ;
      }

      let params = {
        configId: row.config_id,
        orgShare: row.org_share
      }
      updateOrgShare(params).then(res => {
        if (res.data.success) {
          this.$message.success("操作成功");
          this.loadData(this.currenPage);
        }else {
          this.$message.error(res.data.error.message)
        }
      })
    },
    loadGame() {
      getGame().then(res => {
        this.restaurants = [];
        res.data.data.forEach(e => {
          let item = {};
          item.value = e.gameName;
          item.gameId = e.gameId;
          this.restaurants.push(item);
        })
      })
    },
    querySearch(queryString, callback){
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      callback(results);
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item){
      this.queryField.gameId = item.gameId;
    },
    handleGameInput(value){
      const find = this.restaurants.find(e => e.value === this.game.trim());
      if(find){
        this.queryField.gameId = find.gameId;
      }else {
        this.queryField.gameId = '';
      }
    },
    loadOrg(){
      getOrg().then(res => {
        this.restaurants2 = [];
        res.data.data.forEach(e => {
          let item = {};
          item.value = e.orgName;
          item.orgId = e.orgId;
          this.restaurants2.push(item);
        })
      })
    },
    querySearch2(queryString, callback){
      let restaurants = this.restaurants2;
      let results = queryString ? restaurants.filter(this.createStateFilter2(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      callback(results);
    },
    createStateFilter2(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect2(item){
      this.queryField.orgId = item.orgId;
    },
    handleGameInput2(value){
      const find = this.restaurants2.find(e => e.value === this.org.trim());
      if(find){
        this.queryField.orgId = find.orgId;
      }else {
        this.queryField.orgId = '';
      }
    },
    reset(){
      this.queryField = {
        gameId: '',
        orgId: '',
        configId: ''
      }
    }
  }
}
</script>

<style scoped>

</style>