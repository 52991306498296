<template>
  <div>
    <div class="space-query">
      <el-input size="small" placeholder="家族id"/>
      <el-input size="small" placeholder="家族名称"/>
      <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
    </div>
    <el-table
        class="space-table"
        :data="tableData"
        :height="height"
        border
        style="width: 100%">
      <el-table-column
        prop="org_id"
        label="家族id"
        width="80">
      </el-table-column>
      <el-table-column
        prop="org_name"
        label="家族名称"
        width="120">
      </el-table-column>
      <el-table-column
        prop="level"
        label="家族等级"
        width="80">
      </el-table-column>
    </el-table>
    <el-pagination
        class="space-table-data"
        background
        layout="total, prev, pager, next"
        :page-size="pageSize"
        hide-on-single-page
        @current-change="loadData"
        :current-page="currentPage"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {queryOrg} from "@/api/request";

export default {
  name: "OrgList",
  data(){
    return {
      tableData: [],
      height: window.innerHeight - 210,
      total: 0,
      pageSize: 13,
      currentPage: 1,
      queryField: {
        orgId: '',
        orgName: ''
      }
    }
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField 
      }
      queryOrg(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentPage = index;
      })
    },
    reset(){
      this.queryField = {
        orgId: '',
        orgName: ''
      }
    }
  }
}
</script>

<style scoped>

</style>