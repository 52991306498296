<template>
  <div>
    <div class="space-form">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="原密码" prop="password">
          <el-input size="small" v-model="form.password"/>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input size="small" v-model="form.newPassword"/>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirmPassword">
          <el-input size="small" v-model="form.confirmPassword"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="submitForm('form')">修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import crypto from '@/utils/crypto';
import {changePassword} from "@/api/request";

export default {
  name: "ChangePassword",
  data(){
    const equalsPassword = (rule, value, callback) => {
      if (value !== this.form.newPassword) {
        callback(new Error('两次密码输入不一致'));
      }
      else {
        callback();
      }
    };
    return {
      form: {
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        password: [
          { required: true, message: '请输入原密码'},
          { pattern: /^.{6,20}$/, trigger: 'blur', message: '原密码格式错误(6-20位)'},
        ],
        newPassword: [
          { required: true, message: '请输入新密码'},
          { pattern: /^.{6,20}$/, trigger: 'blur', message: '新密码格式错误(6-20位)'},
        ],
        confirmPassword: [
          { required: true, message: '请输入确认新密码'},
          { pattern: /^.{6,20}$/, trigger: 'blur', message: '确认密码格式错误(6-20位)'},
          { validator: equalsPassword, trigger: 'blur' }
        ],
      }
    }
  },
  mounted() {
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            oldPassword: crypto.encrypt(this.form.password),
            newPassword: crypto.encrypt(this.form.newPassword)
          }
          changePassword(params).then(res => {
            if(res.data.success){
              this.$message.success("密码修改成功，3秒后将退出登录");
              setTimeout(() => {
                localStorage.removeItem("userInfo");
                window.location.href = '/login';
              },3000);
            } else {
              this.$message.error(res.data.error.message);
            }          
          })
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>
.space-form{
  width: 300px;
}
</style>