<template>
  <div>
    <div class="space-query">
      <el-input v-model="queryField.resourceId" size="small" placeholder="资源id" />
      <el-select v-model="queryField.mainId" filterable  placeholder="请选择游戏">
        <el-option
          v-for="item in restaurants"
          :key="item.gameId"
          :label="item.value"
          :value="item.gameId">
        </el-option>
      </el-select>
      <el-button size="small" type="primary" @click="loadData(1)">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
      <el-button size="small" type="warning" @click="isDrawer = true">上传游戏资源</el-button>
    </div>
    <div style="margin-top: 10px">
      <el-table
          class="space-table"
          :data="tableData"
          :height="height"
          border
          style="width: 100%">
        <el-table-column
            prop="resource_id"
            label="资源id"
            width="80">
        </el-table-column>
        <el-table-column
            prop="game_name"
            label="游戏名称"
            width="180">
        </el-table-column>
        <el-table-column
            prop="type_name"
            label="资源类型"
            width="120">
        </el-table-column>
        <el-table-column
            prop="url"
            label="资源地址"
            width="300">
        </el-table-column>
        <el-table-column
            prop="sort"
            label="排序值"
            width="80">
        </el-table-column>
        <el-table-column label="操作"  width="150">
					<template slot-scope="scope">
						<el-button size="mini" type="danger" @click="proDelete(scope.row.resource_id)">删除
						</el-button>
					</template>
				</el-table-column>
      </el-table>
      <el-pagination
          class="space-table-data"
          background
          layout="total, prev, pager, next"
          :page-size="pageSize"
          hide-on-single-page
          @current-change="loadData"
          :current-page="currentPage"
          :total="total">
      </el-pagination>
    </div>
    <el-drawer
        title="资源上传"
        :visible.sync="isDrawer"
        direction="rtl">
      <div class="space-drawer">
        <el-form ref="gameResourceFrom" :model="gameResource" :rules="gameResourceRules" label-width="80px">
          <el-form-item label="游戏名称" prop="mainId">
            <el-select v-model="gameResource.mainId" filterable  @change="handleChange" placeholder="请选择游戏">
              <el-option
                v-for="item in restaurants"
                :key="item.gameId"
                :label="item.value"
                :value="item.gameId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="type" label="资源类型">
            <el-select v-model="gameResource.type" placeholder="请选择资源类型">
              <el-option
                v-for="item in types"
                :key="item.code"
                :label="item.desc"
                :value="item.code">
              </el-option>
            </el-select>
				  </el-form-item>
          <el-form-item label="排序值" prop="sort">
            <el-input v-model.trim="gameResource.sort"></el-input>
          </el-form-item>
          <el-form-item label="游戏资源" prop="posterList">
            <el-upload
                ref="videoUpload"
                drag
                :data="param"
                action="#"
                :show-file-list="true"
                :auto-upload="false"
                multiple
                :limit="3"
                :http-request="gameVideoRequest"
                :before-upload="beforeVideoUpload">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">视频只能上传mp4文件，且不超过5GB</div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('gameResourceFrom')">提交</el-button>
            <el-button @click="resetForm('gameResourceFrom')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {addGameResource, getGame, upload, uploadGameVideo, queryGameResourceType, getGameResourceList, deleteGameResource} from "@/api/request";

export default {
  name: "GameVideo",
  data() {
    return {
      queryField: {
        resourceId: "",
        mainId: ""
      },
      game: "",
      tableData:[],
      height: window.innerHeight - 210,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      restaurants: [],
      isDrawer: false,
      types:[],
      gameResource: {
        mainId: '',
        resourceId: '',
        type:'',
        url: '',
        sort: ''
      },
      gameResourceRules: {
        mainId: [
          { required: true, message: '请填写游戏名称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择资源类型', trigger: 'blur' }
        ],
        videoUrl: [
          { required: true, message: '请上传视频', trigger: 'blur' }
        ]
      },
      param: {
        gameId: "",
        progress: 0,
      },
    }
  },
  mounted() {
    this.loadData(1);
    this.loadGame();
    this.loadGameResourceType();
  },
  methods: {

    proDelete(resourceId) {
      deleteGameResource(resourceId).then(res => {
        if (res.data.success) {
            this.$message.success("删除成功");
            this.loadData(1);
          }else {
            this.$message.error(res.data.error.message);
          }
      })
    },

    handleChange(value) {
      this.param.gameId = value;

    },
    loadGameResourceType(){
      queryGameResourceType().then(res => {
        this.types = res.data.data;
      })
    },
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      getGameResourceList(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentPage = index;
      })
    },
    submitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$refs.videoUpload.submit();
        }
      })
    },
    async gameVideoRequest(item){
      // await uploadGameVideo(item).then(res => {
      //   let param = {
      //     game_id: this.gameVideo.gameId,
      //     video_name: this.gameVideo.videoName,
      //     video_tag: this.gameVideo.videoTag,
      //     video_url: res.path,
      //     sort_num: this.gameVideo.videoUrl
      //   }
      //   addGameVideo(param).then(res => {
      //     if (res.data.success) {
      //       this.$message.success("上传成功");
      //     }else {
      //       this.$message.error(res.data.error.message);
      //     }
      //   })
      // })
      let resourceType = this.gameResource.type;
      var prefix;
      var suffix;
      if (resourceType === 3) {
        // 视频
        prefix = "gameVideo/";
        suffix = "/" + Date.now();
      } else if (resourceType === 1) {
        prefix = "image/";
        suffix = "/show";
      } else if (resourceType === 4) {
        prefix = "image/";
        suffix = "/top";
      } else {
        prefix = "image/";
        suffix = "/intro/" + Date.now();
      }
      
      await upload(item,false,param => prefix + param.name + suffix).then(res => {
        let param = {
          main_id: this.gameResource.mainId,
          url: res.path,
          type: this.gameResource.type,
          sort: this.gameResource.sort
        }
        addGameResource(param).then(res => {
          if (res.data.success) {
            this.$message.success("上传成功");
          }else {
            this.$message.error(res.data.error.message);
          }
        })
      })
    },
    beforeVideoUpload(file) {
      let filename = file.name;
      let pos = filename.lastIndexOf('.');
      let suffix = '';
      if (pos !== -1) suffix = filename.substring(pos);
      //判断文件格式 根据文件格式分流判断
      if (suffix === ".mp4") {
        if (!(file.size / 1024 / 1024 / 1024 < 5)) {
          this.$message.error('上传文件大小不能超过 5GB!');
        }
        else {
          return true;
        }
      } else {
        return true;
      }
      return false;
    },
    loadGame() {
      getGame().then(res => {
        this.restaurants = [];
        res.data.data.forEach(e => {
          let item = {};
          item.value = e.gameName;
          item.gameId = e.gameId;
          this.restaurants.push(item);
        })
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.game = "";
      this.$refs.videoUpload.clearFiles();
    },
    reset(){
      this.queryField = {
        resourceId: "",
        mainId: ""
      }
      this.game = "";
    }
  }
}
</script>

<style scoped>

</style>