<template>
  <div>
    <div class="space-query">
      <el-input v-model="queryField.goodsId" placeholder="商品id"></el-input>
      <el-input v-model="queryField.goodsName" placeholder="商品名称"></el-input>
      <el-input v-model="queryField.gameId" placeholder="游戏id"></el-input>
      <el-select v-model="queryField.goodsType">
        <el-option label="奖励类型" value=""></el-option>
        <el-option label="代金券" value="1"></el-option>
        <el-option label="微信红包" value="2"></el-option>
        <el-option label="实物" value="3"></el-option>
      </el-select>
      <el-select v-model="queryField.status">
        <el-option label="商品状态" value=""></el-option>
        <el-option label="在售" value="1"></el-option>
        <el-option label="下架" value="2"></el-option>
      </el-select>
      <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
      <el-button type="warning" size="small" @click="drawerVisible = true">添加商品</el-button>
    </div>
    <el-table
        class="space-table"
        :data="tableData"
        :height="height"
        border
        style="width: 100%">
      <el-table-column
          prop="good_id"
          label="商品id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="good_name"
          label="商品名称"
          width="130">
      </el-table-column>
      <el-table-column
          prop="game_id"
          label="游戏id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="game_name"
          label="游戏名称"
          width="120">
      </el-table-column>
      <el-table-column
          label="商品描述"
          width="150">
        <template v-slot="scope">
          <el-popover
              placement="bottom"
              width="200"
              :content="scope.row.good_desc"
              trigger="click">
            <el-button type="text" slot="reference" style="width: 120px;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
              {{ scope.row.good_desc }}
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
          prop="good_price"
          label="商品价格"
          width="80">
      </el-table-column>
      <el-table-column
          prop="good_transfer"
          label="商品转换价值"
          width="120">
      </el-table-column>
      <el-table-column
          label="商品图片"
          width="80">
        <template v-slot="scope">
          <el-image fit="fill" :src="scope.row.good_icon" style="width: 40px;height: 40px"/>
        </template>
      </el-table-column>
      <el-table-column
          prop="goods_type"
          label="商品类型"
          :formatter="goodsTypeFormatter"
          width="80">
      </el-table-column>
      <el-table-column
          prop="status"
          label="商品状态"
          width="80">
        <template v-slot="scope">
          <span :style="{color: (statusFormatterColor(scope.row.status))}"> {{ statusFormatter(scope.row.status) }} </span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作">
        <template v-slot="scope" style="text-align: left">
          <el-button type="text" size="small" @click="toUploadGoodsImg(scope.row.game_id,scope.row.good_id)">商品图片上传</el-button>
          <el-button type="text" size="small" @click="soldOut(scope.row.good_id)" v-if="scope.row.status === 1">下架</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="space-table-data"
        background
        layout="total, prev, pager, next"
        :page-size="pageSize"
        hide-on-single-page
        @current-change="loadData"
        :current-page="currentPage"
        :total="total">
    </el-pagination>

    <el-drawer
        title="添加商品"
        :visible.sync="drawerVisible"
        :before-close="handleClose"
        direction="rtl">
      <div class="space-drawer">
        <el-form ref="formRule" :model="formRule" :rules="rules">
          <el-form-item label="商品名称" prop="goodsName">
            <el-input v-model.trim="formRule.goodsName" />
          </el-form-item>
          <el-form-item label="商品价格" prop="goodsPrice">
            <el-input v-model.trim.number="formRule.goodsPrice" />
          </el-form-item>
          <el-form-item label="奖励类型" prop="goodsType">
            <el-select v-model="formRule.goodsType">
              <el-option label="奖励类型" value=""></el-option>
              <el-option label="代金券" value="1"></el-option>
              <el-option label="微信红包" value="2"></el-option>
              <el-option label="实物" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="gameId">
            <label slot="label">&nbsp;&nbsp;关联游戏</label>
            <el-autocomplete
                v-model.trim="game"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="商品状态" prop="status">
            <el-select v-model="formRule.status">
              <el-option label="商品状态" value=""></el-option>
              <el-option label="在售" value="1"></el-option>
              <el-option label="下架" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <label slot="label">&nbsp;&nbsp;商品描述</label>
            <el-input type="textarea" v-model.trim="formRule.goodsDesc" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('formRule')">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>

    <el-dialog
        title="商品上传"
        :visible.sync="dialogVisible"
        width="21%">
      <el-upload
          drag
          :data="param"
          action="#"
          accept=".jpg, .jpeg, .png"
          :show-file-list="false"
          :http-request="goodsRequest"
          :before-upload="beforeUploadIcon">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传jpg/png/jpeg文件，且不超过2MB</div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
import {
  addGoods,
  doSoldOut,
  getGame,
  queryShopGoods,
  saveGoodsImg,
  saveLogo,
  upload,
  uploadGoodsImg
} from "@/api/request";

export default {
  name: "ShopGoods",
  data() {
    const validateGameName = (rule, value, callback) => {
      const find = this.restaurants.find(e => e.value === this.game.trim());
      if((this.game === '' && value !== '') || (!find)){
        this.formRule.gameId = value = '';
      }
      if(value === '' && this.game === ''){
        callback(new Error("请输入游戏"));
      } else if(value !== ''){
        callback();
      }else {
        if(find){
          this.formRule.gameId = find.gameId;
          callback();
        }
        callback(new Error("未查询到该游戏"));
      }
    };
    return {
      queryField: {
        goodsId: '',
        goodsName: '',
        goodsType: '',
        gameId: '',
        status: ''
      },
      tableData: [],
      height: window.innerHeight - 210,
      currentPage: 1,
      pageSize: 13,
      total: 1,
      drawerVisible: false,
      formRule: {
        goodsName: "",
        goodsPrice: "",
        goodsType: "",
        gameId: "",
        status: "",
        goodsDesc: ""
      },
      rules: {
        goodsName: [
          { required: true, message: '请填写商品名称', trigger: 'blur' },
        ],
        goodsPrice: [
          { required: true, message: '请填写商品价格', trigger: 'blur' },
          { type: 'number', message: '价格必须为数字'},
        ],
        goodsType: [
          { required: true, message: '请选择商品奖励类型', trigger: 'change' },
        ],
        gameId: [
          { validator: validateGameName, trigger: 'change' }
        ],
        status: [
          { required: true, message: '请选择商品状态', trigger: 'change' },
        ],
      },
      restaurants: [],
      game: "",
      dialogVisible: false,
      param: {
        goodsId: "",
        gameId: ""
      }
    }
  },
  mounted() {
    this.loadData(1);
    this.loadGame();
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      queryShopGoods(params).then(res => {
        this.tableData = res.data.list;
        this.currentPage = index;
        this.total = res.data.total;
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addGoods(this.formRule).then(res => {
            if(res.data.success){
              this.$message.success("商品添加成功");
              this.drawerVisible = false;
              this.loadData(1);
            }else {
              this.$message.error(res.data.error.message);
            }
          })
        } else {
          return false;
        }
      });
    },
    soldOut(goodsId){
      doSoldOut(goodsId).then(res => {
        if(res.data.success){
          this.$message.success("商品下架成功");
          this.loadData(1);
        }else {
          this.$message.error(res.data.error.message);
        }
      })
    },
    loadGame() {
      getGame().then(res => {
        this.restaurants = [];
        res.data.data.forEach(e => {
          let item = {};
          item.value = e.gameName;
          item.gameId = e.gameId;
          this.restaurants.push(item);
        })
      })
    },
    toUploadGoodsImg(gameId,goodsId){
      this.param.gameId = gameId;
      this.param.goodsId = goodsId;
      this.dialogVisible = true;
    },
    async goodsRequest(item){
      // uploadGoodsImg(item).then(res => {
      //   if(res.success){
      //     console.log(res)
      //     let params = {
      //       path: res.data.path,
      //       goodsId: item.data.goodsId
      //     }
      //     saveGoodsImg(params).then(res => {
      //       if(res.data.success){
      //         this.dialogVisible = false;
      //         this.$message.success("上传成功");
      //       }else {
      //         this.$message.error(res.data.error.message);
      //       }
      //     })
      //   }else {
      //     this.$message.error(res.error.message);
      //   }
      // })
      
      await upload(item,false,param => "goods/" + param.name + "/" + param.shortName).then(res => {
        if(res.success){
          let params = {
            path: res.data.path,
            goodsId: item.data.goodsId
          }
          saveGoodsImg(params).then(res => {
            if(res.data.success){
              this.dialogVisible = false;
              this.$message.success("上传成功");
            }else {
              this.$message.error(res.data.error.message);
            }
          })
        }else {
          this.$message.error(res.error.message);
        }
      })
    },
    beforeUploadIcon(file) {
      const isJPEG = file.type === "image/jpeg";
      const isJPG = file.type === "image/jpg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!(isJPG || isPNG || isJPEG)) {
        this.$message.error("上传图片只能是JPG、PNG、JPEG格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      let filename = file.name;
      let pos = filename.lastIndexOf(".");
      let suffix = "";
      if (pos !== -1) suffix = filename.substring(pos);
      return (isJPG || isPNG) && isLt2M;
    },
    handleClose(done) {
      this.formRule = {
        goodsName: "",
        goodsPrice: "",
        goodsType: "",
        gameId: "",
        status: "",
        goodsDesc: ""
      }
      done();
    },
    querySearch(queryString, callback){
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      callback(results);
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item){
      this.formRule.gameId = item.gameId;
    },
    reset(){
      this.queryField = {
        goodsId: '',
        goodsName: '',
        goodsType: '',
        gameId: '',
        status: ''
      }
    },
    goodsTypeFormatter(row, column, cellValue){
      let rep = '';
      if(cellValue === 1){
        rep = "代金券";
      }else if (cellValue === 2){
        rep = "微信红包";
      }else if(cellValue === 3){
        rep = "实物";
      }
      return rep;
    },
    statusFormatter(cellValue){
      let rep = '';
      if(cellValue === 1){
        rep = "在售";
      }else if (cellValue === 2){
        rep = "下架";
      }
      return rep;
    },
    statusFormatterColor(cellValue){
      let rep = "";
      if(cellValue === 1){
        rep = "#67C23A";
      }else if(cellValue === 2){
        rep = "#F56C6C"
      }
      return rep;
    },
  }
}
</script>

<style scoped> 
</style>