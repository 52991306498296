<template>
    <div>
        <div style="display: flex;justify-content: space-between;">
            <div style="flex: 1;display: flex;flex-direction: column;row-gap: 15px;">
                <div style="width: 50%;">
                    <div style="margin-bottom: 15px;font-weight: 700;">礼包id</div>
                    <el-input v-model.trim="form.giftId" />
                </div>
                <div style="width: 50%;">
                    <div style="margin-bottom: 15px;font-weight: 700;">礼包码</div>
                    <el-input v-model.trim="form.giftCode" @input="onInput" type="textarea" placeholder="礼包码以英文逗号分开"/>
                </div>
                <div style="width: 50%">
                    <el-button type="primary" @click="onSubmit">提交</el-button>
                    <el-button @click="back">返回</el-button>
                </div>
            </div>
            <div style="flex: 1;">
                <el-table
                    :data="tableData"
                    border
                    style="width: 100%">
                    <el-table-column
                        prop="id"
                        label="序号"
                        width="80">
                    </el-table-column>
                    <el-table-column
                        prop="giftCode"
                        label="礼包码"
                        width="200">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import {addGiftCode} from "@/api/request";

export default {
    name: "giftcodeadd",
    data(){
        return {
            tableData: [],
            form: {
                giftId: '',
                giftCode: ''
            }
        }
    },
    methods: {
        onSubmit(){
            if(!this.form.giftId || !this.form.giftCode){
                return this.$message.error("礼包id或礼包码不能为空");
            }
            let trim = this.form.giftCode.split(',').filter(f => f);
            this.form.giftCode = trim.join(',');
            this.tableData = [];
            trim.forEach((t,index) => this.tableData.push({id: index + 1,giftCode: t}));
            
            addGiftCode(this.form).then(res => {
                if(res.data.success){
                    this.$message.success("添加成功");
                }else {
                    this.$message.error(res.data.error.message);
                }
            })
        },
        onInput(value){
            if(value.length > 0){
                let arr = value.split(',');
                this.tableData = [];
                for(let i = 0; i < arr.length; i++){
                    this.tableData.push({
                        id: i + 1,
                        giftCode: arr[i]
                    })
                }
            }
        },
        back(){
            this.$router.push('/gift/code');
        }
    }
}
</script>

<style scoped>

</style>