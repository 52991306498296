<template>
  <div>
    <div class="space-query">
      <el-input size="small" placeholder="管理员id"/>
      <el-input size="small" placeholder="昵称"/>
      <el-button type="primary" size="small" @click="">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
      <el-button type="warning" size="small" @click="drawerVisible = true">添加管理员</el-button>
    </div>
    
    <div>
      <el-table
          class="space-table"
          :data="tableData"
          :height="height"
          border
          style="width: 100%">
        <el-table-column
            prop="adminId"
            label="管理员id"
            width="120">
        </el-table-column>
        <el-table-column
            prop="nickname"
            label="昵称"
            width="120">
        </el-table-column>
      </el-table>
      <el-pagination
          class="space-table-data"
          background
          layout="total, prev, pager, next"
          :page-size="pageSize"
          hide-on-single-page
          @current-change="loadData"
          :current-page="currentPage"
          :total="total">
      </el-pagination>
    </div>
    <el-drawer
        title="添加管理员"
        :visible.sync="drawerVisible"
        direction="rtl">
      <div class="space-drawer">
        <el-form ref="formRule" :model="formRule" :rules="rules">
          <el-form-item label="管理员账号" prop="adminName">
            <el-input v-model.trim="formRule.adminName" />
          </el-form-item>
          <el-form-item label="管理员密码" prop="password">
            <el-input v-model.trim="formRule.password" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('formRule')">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {addAdmin, queryAdmin} from "@/api/request";
import crypto from "@/utils/crypto";

export default {
  name: "AdminList",
  data(){
    return {
      queryField: {
        adminId: '',
        nickname: '',
      },
      tableData: [],
      height: window.innerHeight - 210,
      currentPage: 1,
      pageSize: 13,
      total: 0,
      drawerVisible: false,
      formRule: {
        adminName: '',
        password: ''
      },
      rules: {
        adminName: [
          { required: true, message: '请填写管理员账号', trigger: 'blur' },
          {pattern: /^\w{3,20}$/, trigger: 'blur', message: '请输入正确的账号'},
        ],
        password: [
          { required: true, message: '请填写密码', trigger: 'blur' },
          { min: 6, max: 12, message: '密码长度为6-12位', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      queryAdmin(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentPage = index;
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            adminName: this.formRule.adminName,
            password: crypto.encrypt(this.formRule.password)
          }
          addAdmin(params).then(res => {
            if(res.data.success){
              this.drawerVisible = false;
              this.$message.success("管理员添加成功");
              this.loadData(1);
            }else {
              this.$message.error(res.data.error.message)
            }
          })
          
        } else {
          return false;
        }
      });
    },
    reset(){
      this.queryField = {
        adminId: '',
        nickname: '',
      }
    }
  }
}
</script>

<style scoped>
</style>