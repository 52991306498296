<template>
    <div>
        <div class="space-query">
            <el-input v-model="queryField.giftId" size="small" placeholder="礼包id"></el-input>
            <el-input v-model="queryField.giftCode" size="small" placeholder="礼包码"></el-input>
            <long-range-search-game ref="longSearch" size="small" @select="handleSelect" @input="handleInput"></long-range-search-game>
            <el-select v-model="queryField.giftStatus" size="small">
                <el-option label="礼包码状态" value=""></el-option>
                <el-option label="未使用" value="1"></el-option>
                <el-option label="已使用" value="2"></el-option>
            </el-select>
            <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
            <el-button size="small" @click="reset">重置</el-button>
            <el-button type="warning" size="small" @click="toAddGiftCode">添加礼包码</el-button>
        </div>
        <div style="margin-top: 10px">
            <el-table
                class="space-table"
                :data="tableData"
                :height="height"
                border
                style="width: 100%">
                <el-table-column
                    prop="code_id"
                    label="礼包码id"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="gift_code"
                    label="礼包码"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="gift_code_state"
                    label="礼包码状态"
                    :formatter="formatterGiftCodeState"
                    width="100">
                    <template v-slot="scope">
                        <span :style="{color: scope.row.gift_status === 1 ? '#67C23A' : '#F56C6C'}">
                          {{ scope.row.gift_status === 1 ? '未使用' : '已使用'}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="gift_id"
                    label="礼包id"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="gift_name"
                    label="礼包名称"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="game_id"
                    label="游戏id"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="game_name"
                    label="游戏名称"
                    width="120">
                </el-table-column>
            </el-table>
            <el-pagination
                class="space-table-data"
                background
                layout="total, prev, pager, next"
                :page-size="pageSize"
                hide-on-single-page
                @current-change="loadData"
                :current-page="currentPage"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {getGiftCode} from "@/api/request";
import LongRangeSearchGame from "@/components/LongRangeSearchGame.vue";

export default {
    name: "giftcodelist",
    components: {LongRangeSearchGame},
    data(){
        return {
            queryField: {
                giftId: '',
                giftCode: '',
                giftStatus: '',
                gameId: '',
            },
            tableData: [],
            height: window.innerHeight - 210,
            total: 0,
            currentPage: 1,
            pageSize: 15,
        }
    },
    mounted() {
        this.loadData(1);
    },
    methods: {
        loadData(index){
            let params = {
                pageNo: index,
                pageSize: this.pageSize,
                params: this.queryField
            }
            getGiftCode(params).then(res => {
                this.tableData = res.data.list;
                this.total = res.data.total;
                this.currentPage = index;
            })
        },
        handleSelect(item){
            this.queryField.gameId = item.gameId;
        },
        handleInput(item){
            this.queryField.gameId = item.gameId;
        },
        toAddGiftCode(){
            this.$router.push('/gift/code/add');
        },
        reset(){
            this.queryField =  {
                giftId: '',
                giftCode: '',
                giftStatus: '',
                gameId: '',
            }
            this.$refs.longSearch.reset();
            this.loadData(1);
        },
        formatterGiftCodeState(row, column, cellValue){
            let rep = ''
            if(cellValue === 0){
                rep = '未领取'
            }else if(cellValue === 1){
                rep = '已领取'
            }
            return rep
        }
    }
}
</script>

<style scoped>

</style>