<template>
    <div>
        <div class="space-query">
            <el-input size="small" v-model="queryField.userId" placeholder="用户id" />
            <el-input size="small" v-model="queryField.roleCode" placeholder="角色id" />
            <el-input size="small" v-model="queryField.giftCode" placeholder="礼包码" />
            <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
            <el-button size="small" @click="reset">重置</el-button>
        </div>
        <div style="margin-top: 10px">
            <el-table
                class="space-table"
                :data="tableData"
                :height="height"
                border
                style="width: 100%">
                <el-table-column
                    prop="user_id"
                    label="用户id"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="user_name"
                    label="用户名"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="game_name"
                    label="游戏名称"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="gift_name"
                    label="礼包名称"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="role_code"
                    label="角色id"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="gift_code"
                    label="礼包码"
                    width="120">
                </el-table-column>
                <el-table-column
                    prop="add_time"
                    label="领取时间"
                    :formatter="dateFormat"
                    width="120">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import {getGiftRecord} from "@/api/request";
import moment from "moment/moment";

export default {
    name: "giftCodeRecord",
    data(){
        return {
            queryField: {
                giftId: '',
                userId: '',
                roleCode: '',
                giftCode: ''
            },
            tableData: [],
            height: window.innerHeight - 210,
            total: 0,
            currentPage: 1,
            pageSize: 15,
        }
    },
    mounted() {
        this.loadData(1);
    },
    methods: {
        loadData(index){
            let params = {
                pageNo: index,
                pageSize: this.pageSize,
                params: this.queryField
            }
            getGiftRecord(params).then(res => {
                this.tableData = res.data.list;
                this.total = res.data.total;
                this.currentPage = index;
            })
        },
        reset(){
            this.queryField = {
                giftId: '',
                userId: '',
                roleCode: '',
                giftCode: ''
            }
            this.loadData(1);
        },
        dateFormat(row,column){
            let date = row[column.property];
            if(date === undefined){
                return ''
            }
            return moment(date).format("YYYY-MM-DD")
        },
    }
}
</script>

<style scoped>

</style>