<template xmlns="http://www.w3.org/1999/html">
  <div>
    <div class="space-query">
      <el-input v-model="queryField.taskId" size="small" placeholder="任务id" />
      <el-input v-model="queryField.gameId" size="small" placeholder="游戏id" />
      <el-select size="small" v-model="queryField.taskType">
        <el-option label="任务类型" value=""></el-option>
        <el-option label="通用" value="1"></el-option>
        <el-option label="需要接受" value="2"></el-option>
      </el-select>
      <el-button size="small" type="primary" @click="loadData(1)">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
      <el-button size="small" type="warning" @click="drawerVisible = true">添加任务</el-button>
    </div>
    <el-table
        class="space-table"
        :data="tableData"
        :height="height"
        border
        style="width: 100%">
      <el-table-column
          prop="task_id"
          label="任务id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="task_name"
          label="任务名"
          width="120">
      </el-table-column>
      <el-table-column
          prop="task_type"
          label="任务类型"
          :formatter="taskTypeFormatter"
          width="80">
      </el-table-column>
      <el-table-column
          prop="task_prize"
          label="任务奖品"
          width="120">
      </el-table-column>
      <el-table-column
          prop="game_name"
          label="游戏名"
          width="120">
      </el-table-column>
      <el-table-column
          label="任务视频"
          width="80">
        <template v-slot="scope">
          <el-button v-if="scope.row.task_video_url" type="text" size="small" @click="toWatchVideo(scope.row.task_video_url)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column
          prop="task_tag"
          label="任务标签"
          width="150">
      </el-table-column>
      <el-table-column
          prop="sort_num"
          label="排序值"
          sortable
          width="100">
      </el-table-column>
      <el-table-column
          label="商品描述"
          width="150">
        <template v-slot="scope">
          <el-popover
              placement="bottom"
              width="200"
              :content="scope.row.task_desc"
              trigger="click">
            <el-button type="text" slot="reference" style="width: 120px;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
              {{ scope.row.task_desc }}
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
      label="操作">
        <template v-slot="scope">
          <el-button type="text" size="small" @click="toUploadVideo(scope.row.task_id,scope.row.game_id)">上传视频</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="space-table-data"
        background
        layout="total, prev, pager, next"
        :page-size="pageSize"
        hide-on-single-page
        @current-change="loadData"
        :current-page="currentPage"
        :total="total">
    </el-pagination>

    <el-dialog
        :visible.sync="dialogVisible"
        :before-close="handClose"
        width="30%">
        <video controls autoplay style="width: 100%;height: 100%" ref="taskVideo" :src="gameTaskUrl"></video>
    </el-dialog>

    <el-drawer
        title="添加游戏任务"
        :visible.sync="drawerVisible"
        :before-close="handleClose"
        direction="rtl">
      <div class="space-drawer">
        <el-form ref="formRule" :model="formRule" :rules="rules">
          <el-form-item prop="taskName">
            <label slot="label">&nbsp;&nbsp;&nbsp;任务名</label>
            <el-input v-model.trim="formRule.taskName" />
          </el-form-item>
          <el-form-item label="任务类型" prop="taskType">
            <el-select v-model.trim="formRule.taskType">
              <el-option label="通用" value="1"></el-option>
              <el-option label="需要接收" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务奖品" prop="taskPrize">
            <el-input v-model.trim.number="formRule.taskPrize"/>
          </el-form-item>
          <el-form-item prop="gameId">
            <label slot="label">&nbsp;&nbsp;游戏名称</label>
            <el-autocomplete
                v-model.trim="game"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="任务标签">
            <label slot="label">&nbsp;&nbsp;任务标签</label>
            <el-input v-model.trim="formRule.taskTag" />
          </el-form-item>
          <el-form-item label="排序值" prop="sortNum">
            <label slot="label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;排序值</label>
            <el-input v-model.trim.number="formRule.sortNum" />
          </el-form-item>
          <el-form-item label="任务描述">
            <label slot="label">&nbsp;&nbsp;任务描述</label>
            <el-input type="textarea" autosize v-model.trim="formRule.taskDesc" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('formRule')">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>

    <el-dialog
        title="视频上传"
        :visible.sync="dialogVisible2"
        :close-on-click-modal="false"
        width="21%">
      <el-upload
          drag
          :data="param"
          action="#"
          accept=".mp4"
          :show-file-list="false"
          :http-request="videoRequest"
          :before-upload="beforeVideoUpload">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传mp4文件，且不超过5GB</div>
      </el-upload>
      <el-progress :percentage="param.progress"></el-progress>
    </el-dialog>
  </div>
</template>

<script>
import {addGameTask, getGame, queryTask, saveGameTaskVideo, upload, uploadGameTaskVideo} from "@/api/request";

export default {
  name: "GameTask",
  data(){
    const validateGameName = (rule, value, callback) => {
      const find = this.restaurants.find(e => e.value === this.game.trim());
      if((this.game === '' && value !== '') || (!find)){
        this.formRule.gameId = value = '';
      }
      if(value === '' && this.game === ''){
        callback(new Error("请输入游戏"));
      } else if(value !== ''){
        callback();
      }else {
        if(find){
          this.formRule.gameId = find.gameId;
          callback();
        }
        callback(new Error("未查询到该游戏"));
      }
    };
    return {
      queryField: {
        taskId: '',
        taskType: '',
        gameId: ''
      },
      tableData: [],
      pageSize: 13,
      currentPage: 1,
      total: 0,
      height: window.innerHeight - 210,
      dialogVisible: false,
      gameTaskUrl: '',
      drawerVisible: false,
      formRule: {
        taskName: '',
        taskDesc: '',
        taskType: '',
        taskPrize: 0,
        taskTag: '',
        sortNum: 1,
        gameId: ''
      },
      rules: {
        taskName: [
          { required: true, message: '请填写任务名称', trigger: 'blur' }
        ],
        taskType: [
          { required: true, message: '请选择任务类型', trigger: 'blur' }
        ],
        taskPrize: [
          { type: 'number', message: '任务奖励必须为数字'},
          { required: true, message: '请填写任务奖励', trigger: 'blur' }
        ],
        gameId:[
          { validator: validateGameName, trigger: 'change' }
        ],
        sortNum: [
          { type: 'number', message: '排序值必须为数字'},
        ]
      },
      game: "",
      restaurants: [],
      dialogVisible2: false,
      param: {
        gameId: '',
        progress: 0,
        taskId: ''
      }
    }
  },
  mounted() {
    this.loadData(1);
    this.loadGame();
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      queryTask(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentPage = index;
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addGameTask(this.formRule).then(res => {
            if(res.data.success){
              this.drawerVisible = false;
              this.formRule = {
                taskName: '',
                taskDesc: '',
                taskType: '',
                taskPrize: 0,
                taskTag: '',
                sortNum: 1,
                gameId: ''
              }
              this.game = '';
              this.$message.success("任务添加成功");
              this.loadData(1);
            }else {
              this.$message.error(res.data.error.message);
            }
          })
        } else {
          return false;
        }
      });
    },
    loadGame() {
      getGame().then(res => {
        this.restaurants = [];
        res.data.data.forEach(e => {
          let item = {};
          item.value = e.gameName;
          item.gameId = e.gameId;
          this.restaurants.push(item);
        })
      })
    },
    async videoRequest(item){
      // await uploadGameTaskVideo(item).then(res => {
      //   if(res.success){
      //     saveGameTaskVideo(item.data.taskId).then(res => {
      //       if(res.data.success){
      //         this.dialogVisible2 = false;
      //         this.$message.success("视频上传成功");
      //       }else {
      //         this.$message.error(res.data.error.message);
      //       }
      //     })
      //   }else {
      //     this.$message.error(res.error.message);
      //   }
      // });
      
      await upload(item,true,param => "task/" + item.data.taskId + "/task").then(res => {
        if(res.success){
          saveGameTaskVideo(item.data.taskId).then(res => {
            if(res.data.success){
              this.dialogVisible2 = false;
              this.$message.success("视频上传成功");
            }else {
              this.$message.error(res.data.error.message);
            }
          })
        }else {
          this.$message.error(res.error.message);
        }
      })
    },
    // 上传文件之前的钩子
    beforeVideoUpload(file) {
      let filename = file.name;
      let pos = filename.lastIndexOf('.');
      let suffix = '';
      if (pos !== -1) suffix = filename.substring(pos);
      //判断文件格式 根据文件格式分流判断
      if (suffix === ".mp4") {
        if (!(file.size / 1024 / 1024 / 1024 < 5)) {
          this.$message.error('上传文件大小不能超过 5GB!');
        }
        else {
          return true
        }
      } else {
        this.$message.error('只能上传mp4文件');
      }
      return false;
    },
    toUploadVideo(id,gameId){
      this.dialogVisible2 = true;
      this.param.gameId = gameId;
      this.param.taskId = id;
    },
    handleClose(done) {
      this.formRule = {
        taskName: '',
        taskDesc: '',
        taskType: '',
        taskPrize: 0,
        taskTag: '',
        sortNum: 1,
        gameId: ''
      }
      this.game = '';
      done();
    },
    querySearch(queryString, callback){
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      callback(results);
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item){
      this.formRule.gameId = item.gameId;
    },
    reset(){
      this.queryField = {
        taskId: '',
        taskType: '',
        gameId: ''
      }
    },
    toWatchVideo(url){
      this.dialogVisible = true;
      this.gameTaskUrl = url;
    },
    handClose(done){
      this.gameTaskUrl = '';
      done();
    },
    taskTypeFormatter(row, column, cellValue, index){
      let rep = '';
      if(cellValue === 1){
        rep = '通用';
      }else if(cellValue === 2){
        rep = '需要接受';
      }
      return rep;
    }
  }
}
</script>

<style scoped>

</style>