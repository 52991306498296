<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="游戏名称">
            <long-range-search-game ref="longSearch" 
                                    style="width: 100%;" 
                                    @select="handleSelect"
                                    @input="handleInput"
            ></long-range-search-game>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="礼包名称">
            <el-input v-model.trim="form.giftName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="礼包描述">
            <el-input type="textarea" maxlength="500"
                      :autosize="{ minRows: 1, maxRows: 5 }" 
                      v-model.trim="form.giftDesc"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="礼包内容">
            <el-input type="textarea" maxlength="500"
                      :autosize="{ minRows: 1, maxRows: 5 }" 
                      v-model.trim="form.giftDetail"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="礼包类型">
            <el-select v-model.trim="form.giftType">
              <el-option label="通码" value="1"></el-option>
              <el-option label="非通码" value="2"></el-option>
              <el-option label="无码" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="礼包状态">
            <el-select v-model.trim="form.giftStatus">
              <el-option label="正常" value="1"></el-option>
              <el-option label="下架" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="开始时间" v-model.trim="form.startTime">
            <el-date-picker
                clearable
                v-model.trim="form.startTime"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="结束时间">
            <el-date-picker
                clearable
                v-model.trim="form.endTime"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="礼包限制">
            <el-select v-model.trim="form.giftLimit" placeholder="限制账号或角色只能领取一次">
              <el-option label="账号限制" value="1"></el-option>
              <el-option label="角色限制" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="礼包码" v-show="form.giftType === '1'">
            <el-input v-model.trim="form.giftCode"></el-input>
          </el-form-item>
          
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item style="margin-top: 50px;">
            <div style="width: fit-content;margin: 0 auto 0 calc(50% - 170px);">
              <el-button @click="submit" type="primary">提交</el-button>
              <el-button @click="toGiftList">取消</el-button>
              <el-button @click="reset" type="warning">重置</el-button>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import LongRangeSearchGame from "@/components/LongRangeSearchGame.vue";
import {addGift} from "@/api/request";

export default {
  name: "giftAdd",
  components: {LongRangeSearchGame},
  data(){
    return {
      form: {
        gameId: '',
        giftName: '',
        giftDesc: '',
        giftDetail: '',
        giftType: '2',
        giftStatus: '1',
        startTime: '',
        endTime: '',
        giftLimit: '1',
        giftCode: '',
      }
    }
  },
  methods: {
    submit(){
      let params = {
        game_id: this.form.gameId,
        gift_name: this.form.giftName,
        gift_desc: this.form.giftDesc,
        gift_detail: this.form.giftDetail,
        gift_type: this.form.giftType,
        gift_status: this.form.giftStatus,
        start_time: this.form.startTime + " 00:00:00",
        end_time: this.form.endTime + " 00:00:00",
        gift_limit: this.form.giftLimit,
        gift_code: this.form.giftCode,
      }
      if(!params.game_id || !params.gift_name){
        return this.$message.error("游戏名称和礼包名称不能为空");
      }
      if(params.gift_type === '1' && !params.gift_code){
        return this.$message.error("礼包码不能为空");
      }
      addGift(params).then(res => {
        if(res.data.success){
          this.$message.success("添加成功");
          this.reset();
        }else {
          this.$message.error(res.data.error.message);
        }
      })
    },
    toGiftList(){
      this.$router.push('/gift/list');
      this.reset();
    },
    handleSelect(item){
      this.form.gameId = item.gameId;
    },
    handleInput(item){
      this.form.gameId = item.gameId;
    },
    reset(){
      this.form = {
        gameId: '',
        giftName: '',
        giftDesc: '',
        giftDetail: '',
        giftType: '2',
        giftStatus: '1',
        startTime: '',
        endTime: '',
        giftLimit: '1',
        giftCode: '',
      }
      this.$refs.longSearch.reset();
    }
  }
}
</script>

<style scoped>
::v-deep .el-select,.el-date-editor.el-input {
  width: 100%;
}
</style>