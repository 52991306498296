<template>
  <div>
    <div class="space-query">
      <el-input v-model="queryField.accountId" size="small" placeholder="子账号id"/>
      <el-input v-model="queryField.userId" size="small" placeholder="主账号id"/>
      <el-input v-model="queryField.gameId" size="small" placeholder="游戏id"/>
      <el-select v-model="queryField.accountStatus" size="small">
        <el-option label="子账号状态" value=""></el-option>
        <el-option label="正常使用" value="1"></el-option>
        <el-option label="冻结" value="2"></el-option>
        <el-option label="隐藏" value="3"></el-option>
      </el-select>
      <el-select v-model="queryField.tradable" size="small">
        <el-option label="交易状态" value=""></el-option>
        <el-option label="不可交易" value="0"></el-option>
        <el-option label="可交易" value="1"></el-option>
      </el-select>
      <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
      <el-button size="small" @click="reset" >重置</el-button>
    </div>
    <el-table
        class="space-table"
        :data="tableData"
        :height="height"
        border
        style="width: 100%">
      <el-table-column
        prop="account_id"
        label="子账号id"
        width="80">
      </el-table-column>
      <el-table-column
          prop="user_id"
          label="用户id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="user_name"
          label="主账号"
          width="120">
      </el-table-column>
      <el-table-column
          prop="game_name"
          label="游戏名称"
          width="120">
      </el-table-column>
      <el-table-column
          prop="account_name"
          label="子账号"
          width="120">
      </el-table-column>
      <el-table-column
          prop="account_status"
          label="子账号状态"
          width="100">
        <template v-slot="scope">
          <span :style="{color: (accountStatusFormatterColor(scope.row.account_status))}">{{ accountStatusFormatter(scope.row.account_status) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="tradable"
          label="是否可交易"
          width="100">
        <template v-slot="scope">
          <span :style="{color: (tradableFormatterColor(scope.row.tradable))}">{{ tradableFormatter(scope.row.tradable) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="space-table-data"
        background
        layout="total, prev, pager, next"
        :page-size="pageSize"
        hide-on-single-page
        @current-change="loadData"
        :current-page="currentPage"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {queryUserAccount} from "@/api/request";

export default {
  name: "UserAccountList",
  data(){
    return {
      queryField: {
        accountId: '',
        userId: '',
        gameId: '',
        accountStatus: '',
        tradable: ''
      },
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 13,
      height: window.innerHeight - 210,
    }
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      queryUserAccount(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentPage = index;
      })
    },
    reset(){
      this.queryField = {
        accountId: '',
        userId: '',
        gameId: '',
        accountStatus: '',
        tradable: ''
      }
    },
    accountStatusFormatter(cellValue){
      let rep = "";
      if(cellValue === 1){
        rep = "正常使用";
      }else if(cellValue === 2){
        rep = "冻结";
      }else if(cellValue === 3){
        rep = "隐藏";
      }
      return rep;
    },
    accountStatusFormatterColor(cellValue){
      let color = "";
      if(cellValue === 1){
        color = "#67C23A";
      }else if(cellValue === 2){
        color = "#F56C6C";
      }else if(cellValue === 3){
        color = "#E6A23C";
      }
      return color;
    },
    tradableFormatter(cellValue){
      let rep = "";
      if(cellValue === 0){
        rep = "不可交易";
      }else if (cellValue === 1){
        rep = "可交易";
      }
      return  rep;
    },
    tradableFormatterColor(cellValue){
      let color = "";
      if(cellValue === 0){
        color = "#F56C6C";
      }else if(cellValue === 1){
        color = "#67C23A";
      }
      return color;
    }
  }
}
</script>

<style scoped>

</style>