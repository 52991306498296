<template>
  <div>
    <div class="space-query">
      <el-input v-model.trim="queryField.userId" size="small" placeholder="用户id" />
      <el-input v-model.trim="queryField.userName" size="small" placeholder="用户账号" />
      <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
    </div>
    <el-table
        class="space-table"
        :data="tableData"
        :height="height"
        border
        style="width: 100%">
      <el-table-column
          prop="user_id"
          label="用户id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="user_name"
          label="用户账号"
          width="120">
      </el-table-column>
      <el-table-column
          prop="invite_code"
          label="邀请码"
          width="120">
      </el-table-column>
      <el-table-column
          prop="real_name"
          label="真实姓名"
          width="120">
      </el-table-column>
      <el-table-column
          prop="phone"
          label="手机号码"
          width="120">
      </el-table-column>
      <el-table-column
          prop="card_no"
          label="身份证号"
          width="160">
      </el-table-column>
      <el-table-column
          prop="real_name"
          label="真实姓名"
          width="120">
      </el-table-column>
      <el-table-column
          label="是否成年"
          width="100">
        <template v-slot="scope">
          <span :style="{color: scope.row.is_adult === 1 ? '#67C23A' : '#F56C6C'}">
            {{ scope.row.is_adult === 1 ? '成年' : '未成年'}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          label="用户状态"
          width="80">
        <template v-slot="scope">
          <span :style="{color: scope.row.user_status === 1 ? '#67C23A' : '#F56C6C'}">
            {{ scope.row.user_status === 1 ? '正常' : '禁用' }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="space-table-data"
        background
        layout="total, prev, pager, next"
        :page-size="pageSize"
        hide-on-single-page
        @current-change="loadData"
        :current-page="currentPage"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {queryUser} from "@/api/request";

export default {
  name: "UserList",
  data(){
    return {
      queryField: {
        userId: '',
        userName: '',
      },
      tableData: [],
      height: window.innerHeight - 210,
      currentPage: 1,
      pageSize: 13,
      total: 0,
    }
  },
  mounted() {
    this.loadData(1); 
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      queryUser(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentPage = index;
      })
    },
    reset(){
      this.queryField = {
        userId: '',
        userName: '',
        nickname: '',
        userStatus: '',
      }
    }
  }
}
</script>

<style scoped>

</style>