<template>
  <div>
    <div class="space-query">
      <el-input v-model="queryField.contentId" size="small" placeholder="攻略id"></el-input>
      <long-range-search-game size="small"></long-range-search-game>
      <el-select v-model="queryField.contentType" size="small">
        <el-option label="攻略" value="1"></el-option>
      </el-select>
      <el-select v-model="queryField.status" size="small">
        <el-option label="全部" value=""></el-option>
        <el-option label="正常" value="1"></el-option>
        <el-option label="下载" value="2"></el-option>
      </el-select>
      <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
      <el-button size="small">重置</el-button>
      <el-button type="warning" size="small" @click="toAdd">添加</el-button>
    </div>
    <div style="margin-top: 10px">
      <el-table
          class="space-table"
          :data="tableData"
          :height="height"
          border
          style="width: 100%">
        <el-table-column
            prop="content_id"
            label="攻略id"
            width="80">
        </el-table-column>
        <el-table-column
            prop="game_name"
            label="游戏名称"
            width="120">
        </el-table-column>
        <el-table-column
            prop="content_name"
            label="攻略名称"
            width="150">
        </el-table-column>
        <el-table-column
            prop="content_type"
            label="攻略类型"
            :formatter="formatterContentType"
            width="80">
        </el-table-column>
        <el-table-column
            width="200"
            label="攻略描述">
          <template v-slot="scope">
            <el-popover
                placement="bottom"
                width="200"
                :content="scope.row.content_des"
                trigger="click">
              <el-button type="text" slot="reference" style="width: 120px;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                {{ scope.row.content_des }}
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            :formatter="formatterStatus"
            width="80">
        </el-table-column>
        <el-table-column
            width="200"
            label="操作">
          <template v-slot="scope">
            <el-button type="text" @click="toEdit(scope.row.content_id)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import LongRangeSearchGame from "@/components/LongRangeSearchGame.vue";
import {queryContent} from "@/api/request";

export default {
  name: "ContentList",
  components: {LongRangeSearchGame},
  data(){
    return {
      queryField: {
        contentId: '',
        gameId: '',
        contentType: '1',
        status: '',
      },
      tableData: [],
      height: window.innerHeight - 210,
      total: 0,
      currentPage: 1,
      pageSize: 15,
    }
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      queryContent(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentPage = res.data.pageNo;
      })
    },
    toAdd(){
      this.$router.push('/content/add');
    },
    toEdit(contentId){
      this.$router.push(`/content/edit/${contentId}`);
    },
    formatterContentType(row, column, cellValue){
      let rep = ''
      if(cellValue === 1){
        rep = '攻略'
      }else if(cellValue === 2){
        rep = '活动'
      }else if(cellValue === 3){
        rep = '公告'
      }else if(cellValue === 4){
        rep = '咨询'
      }else if(cellValue === 5){
        rep = '视频'
      }
      return rep
    },
    formatterStatus(row, column, cellValue){
      let rep = ''
      if(cellValue === 1){
        rep = '正常'
      }else if(cellValue === 2){
        rep = '下架'
      }
      return rep
    },
  }
}
</script>

<style scoped>

</style>