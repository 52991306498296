<template>
  <div>
    <div class="space-query">
      <el-input v-model="queryField.giftId" size="small" placeholder="礼包id" />
      <long-range-search-game ref="longSearch" size="small"></long-range-search-game>
      <el-select size="small" v-model="queryField.giftType">
        <el-option label="礼包类型" value=""></el-option>
        <el-option label="通码" value="1"></el-option>
        <el-option label="非通码" value="2"></el-option>
        <el-option label="无码" value="3"></el-option>
      </el-select>
      <el-select size="small" v-model="queryField.giftStatus">
        <el-option label="礼包状态" value=""></el-option>
        <el-option label="正常" value="1"></el-option>
        <el-option label="下架" value="2"></el-option>
      </el-select>
      <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
      <el-button type="warning" size="small" @click="toAddGift">添加礼包</el-button>
    </div>
    <div style="margin-top: 10px">
      <el-table
          class="space-table"
          :data="tableData"
          :height="height"
          border
          style="width: 100%">
        <el-table-column
            prop="gift_id"
            label="礼包id"
            width="80">
        </el-table-column>
        <el-table-column
            prop="game_name"
            label="游戏名称"
            width="120">
        </el-table-column>
        <el-table-column
            prop="gift_name"
            label="礼包名称"
            width="120">
        </el-table-column>
        <el-table-column
            label="礼包内容"
            width="200">
          <template v-slot="scope">
            <el-popover
                placement="bottom"
                width="200"
                :content="scope.row.gift_detail"
                trigger="click">
              <el-button type="text" slot="reference" style="width: 120px;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                {{ scope.row.gift_detail }}
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            label="礼包描述"
            width="200">
          <template v-slot="scope">
            <el-popover
                placement="bottom"
                width="200"
                :content="scope.row.gift_desc"
                trigger="click">
              <el-button type="text" slot="reference" style="width: 120px;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                {{ scope.row.gift_desc }}
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            prop="gift_type"
            label="礼包类型"
            :formatter="formatterType"
            width="80">
        </el-table-column>
        <el-table-column
            prop="gift_status"
            label="礼包状态"
            :formatter="formatterStatus"
            width="80">
          <template v-slot="scope">
            <span :style="{color: scope.row.gift_status === 1 ? '#67C23A' : '#F56C6C'}">
              {{ scope.row.gift_status === 1 ? '正常' : '下架'}}
            </span>
          </template>
        </el-table-column>
        <el-table-column
            prop="gift_limit"
            label="礼包限制"
            :formatter="formatterLimit"
            width="80">
        </el-table-column>
        <el-table-column
            prop="gift_code"
            label="通用礼包码"
            width="100">
        </el-table-column>
        <el-table-column
            prop="start_time"
            label="开始时间"
            :formatter="dateFormat"
            width="120">
        </el-table-column>
        <el-table-column
            prop="end_time"
            label="结束时间"
            :formatter="dateFormat"
            width="120">
        </el-table-column>
        <el-table-column
            label="操作">
          <template v-slot="scope">
            <el-button type="text" size="small" @click="toEditGift(scope.row.gift_id)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          class="space-table-data"
          background
          layout="total, prev, pager, next"
          :page-size="pageSize"
          hide-on-single-page
          @current-change="loadData"
          :current-page="currentPage"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import LongRangeSearchGame from "@/components/LongRangeSearchGame.vue";
import {queryGiftList} from "@/api/request";
import moment from "moment";

export default {
  name: "giftlist",
  components: {LongRangeSearchGame},
  data(){
    return {
      queryField: {
        giftId: '',
        gameId: '',
        giftType: '',
        giftStatus: '',
        giftLimit: '',
      },
      game: '',
      tableData: [],
      height: window.innerHeight - 210,
      total: 0,
      currentPage: 1,
      pageSize: 15,
    }
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      queryGiftList(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentPage = index;
      })
    },
    toAddGift(){
      this.$router.push('/gift/add');
    },
    toEditGift(giftId){
      this.$router.push(`/gift/edit/${giftId}`);
    },
    reset(){
      this.queryField = {
        giftId: '',
        gameId: '',
        giftType: '',
        giftStatus: '',
        giftLimit: '',
      }
      this.$refs.longSearch.reset();
      this.loadData(this.currentPage);
    },
    dateFormat(row,column){
      let date = row[column.property];
      if(date === undefined){
        return ''
      }
      return moment(date).format("YYYY-MM-DD")
    },
    formatterStatus(row, column, cellValue, index){
      let rep = "";
      if(cellValue === 1){
        rep = "正常";
      }else if(cellValue === 2){
        rep = "下架";
      }
      return rep;
    },
    formatterType(row, column, cellValue, index){
      let rep = "";
      if(cellValue === 1){
        rep = "通码";
      }else if(cellValue === 2){
        rep = "非通码";
      }else if(cellValue === 3){
        rep = "无码"
      }
      return rep;
    },
    formatterLimit(row, column, cellValue, index){
      let rep = "";
      if(cellValue === 1){
        rep = "账号限制";
      }else if(cellValue === 2){
        rep = "角色限制";
      }
      return rep;
    }
  }
}
</script>

<style scoped>

</style>