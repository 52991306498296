import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

router.beforeEach((to,from,next) => {
  if(to.meta.title){
    document.title = to.meta.title;
  }
  if (to.name !== 'Login') {
    let token = localStorage.getItem('token');
    if (!token) {
      window.location.href = "/login";
    } else {
      localStorage.setItem('beforeUrl', from.fullPath);
      next();
    }
  } else {
    next();
  }
})

router.afterEach(() => {
  // NProgress.done()
})


//  全局组件之间通信
Vue.prototype.$viewerBus = Vue.prototype.$viewerBus || new Vue()

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
