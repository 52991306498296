<template>
  <div class="container">
    <div class="g-glass">
      <div class="login-title">登录</div>
      <div style="margin-top: 20px">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm">
          <el-form-item prop="account">
            <el-input v-model.trim="ruleForm.account" placeholder="请输入账号"/>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model.trim="ruleForm.password" show-password validate-event placeholder="请输入密码"/>
          </el-form-item>
          <el-form-item>
            <el-button @click="submitForm('ruleForm')" type="warning" style="width: 325px;font-size: 18px">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import crypto from '../../utils/crypto';
import {login} from "@/api/request";
export default {
  name: "login",
  data(){
    return {
      ruleForm: {
        account: '',
        password: ''
      },
      rules:{
        account: [
          {pattern: /^\w{3,20}$/, trigger: 'blur', message: '请输入正确的账号'},
          {required: true, message: '请输入账号', trigger: 'blur' },
        ],
        password: [
          {pattern: /^.{6,18}$/, trigger: 'blur', message: '密码长度在6-18位之间'},
          {required: true, message: '请输入账号', trigger: 'blur' },
        ]
      },
      isLoading: false,
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            account: this.ruleForm.account,
            password: crypto.encrypt(this.ruleForm.password),
          }
          login(params).then(res => {
            if(res.data.success){
              localStorage.setItem("userInfo",JSON.stringify(res.data.data));
              localStorage.setItem("token",res.data.data.token);
              this.$router.replace({name: "Home"});
            }else {
              this.$message({
                type: "error",
                message: res.data.error.message
              })
            }
          })
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>
  .container {
    width: 100%;
    height: calc(100vh - 16px);
    background: url("../../assets/bg.png") no-repeat fixed;
    background-size: 100% 100%;
    border-radius: 10px;
    user-select: none;
  }
  .g-glass {
    width: 500px;
    height: 400px;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(8px);
    background: rgba(255, 255, 255, 0.2);
    box-shadow:inset 0 0 6px rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -250px;
    margin-top: -200px;
    text-align: center;
  }
  .login-title{
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-size: 36px;
    font-weight: bold
  }
  .input-item{
    display: block;
    width: 300px;
    padding: 10px;
    margin: 30px auto;
    border: 0;
    border-bottom: 1px solid black;
    font-size: 20px;
    outline: none;
    background-color: transparent;
  }
  
  .el-form {
    width: 330px;
    margin: 0 auto;
  }
  .el-form-item{
    margin-bottom: 25px;
  }
  /deep/ .el-form-item__error{
    color: #e83a3a;
  }
  
  .el-input {
    width: 330px;
    margin-bottom: 5px;
  }
  /deep/ .el-input__inner{
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid black;
    border-radius: 0;
  }
  /deep/ .el-input__inner::placeholder {
    color: #818181;
  }
  /deep/ .el-input__inner::-webkit-input-placeholder {
    color: #818181;
  }
  
</style>