<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px" v-loading="isLoading">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="游戏名称">
            <el-input v-model="game" disabled/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="礼包名称">
            <el-input v-model.trim="form.giftName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="礼包描述">
            <el-input type="textarea" maxlength="500"
                      :autosize="{ minRows: 1, maxRows: 5 }" 
                      v-model.trim="form.giftDesc"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="礼包内容">
            <el-input type="textarea" maxlength="500"
                      :autosize="{ minRows: 1, maxRows: 5 }" 
                      v-model.trim="form.giftDetail"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="礼包类型">
            <el-select v-model.trim="form.giftType">
              <el-option label="通码" :value="1"></el-option>
              <el-option label="非通码" :value="2"></el-option>
              <el-option label="无码" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="礼包状态">
            <el-select v-model.trim="form.giftStatus">
              <el-option label="正常" :value="1"></el-option>
              <el-option label="下架" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="开始时间" v-model.trim="form.startTime">
            <el-date-picker
                clearable
                v-model.trim="form.startTime"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="结束时间">
            <el-date-picker
                clearable
                v-model.trim="form.endTime"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="礼包限制">
            <el-select v-model.trim="form.giftLimit" placeholder="限制账号或角色只能领取一次">
              <el-option label="账号限制" :value="1"></el-option>
              <el-option label="角色限制" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="礼包码" v-show="form.giftType === 1">
            <el-input v-model.trim="form.giftCode"></el-input>
          </el-form-item>
          
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item style="margin-top: 50px;">
            <div style="width: fit-content;margin: 0 auto 0 calc(50% - 170px);">
              <el-button @click="submit" type="primary">保存</el-button>
              <el-button @click="toGiftList">取消</el-button>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {editGift, getGift} from "@/api/request";

export default {
  name: "giftAdd",
  data(){
    return {
      form: {
        giftId: '',
        giftName: '',
        giftDesc: '',
        giftDetail: '',
        giftType: '',
        giftStatus: '',
        startTime: '',
        endTime: '',
        giftLimit: '',
        giftCode: '',
      },
      game: '',
      giftId: '',
      isLoading: true,
    }
  },
  mounted() {
    this.giftId = this.$route.params.pathMatch;
    this.loadGift();
  },
  methods: {
    submit(){
      let params = {
        gift_id: this.form.giftId,
        game_id: this.form.gameId,
        gift_name: this.form.giftName,
        gift_desc: this.form.giftDesc,
        gift_detail: this.form.giftDetail,
        gift_type: this.form.giftType,
        gift_status: this.form.giftStatus,
        start_time: this.form.startTime + " 00:00:00",
        end_time: this.form.endTime + " 00:00:00",
        gift_limit: this.form.giftLimit,
        gift_code: this.form.giftCode,
      }
      editGift(params).then(res => {
        if(res.data.success){
          this.$message.success("编辑成功");
          this.loadGift();
        }else {
          this.$message.error(res.data.error.message);
        }
      })
    },
    loadGift(){
      getGift(this.giftId).then(res => {
        if(res.data.success){
          this.isLoading = false;
          let _data = res.data.data;
          this.game = _data.game_name;
          this.form.giftId = _data.gift_id;
          this.form.giftName = _data.gift_name;
          this.form.giftDesc = _data.gift_desc;
          this.form.giftDetail = _data.gift_detail;
          this.form.giftType = _data.gift_type;
          this.form.giftStatus = _data.gift_status;
          this.form.startTime = _data.start_time;
          this.form.endTime = _data.end_time;
          this.form.giftLimit = _data.gift_limit;
          this.form.giftCode = _data.gift_code;
        }
      })
    },
    toGiftList(){
      this.$router.push('/gift/list');
    },
  }
}
</script>

<style scoped>
::v-deep .el-select,.el-date-editor.el-input {
  width: 100%;
}
</style>