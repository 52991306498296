<template>
  <div>
    <div class="space-query">
      <el-input v-model="queryField.noticeId" size="small" placeholder="公告id"></el-input>
      <el-select v-model="queryField.status">
        <el-option label="通知状态" value=""></el-option>
        <el-option label="生效" value="1"></el-option>
        <el-option label="失效" value="2"></el-option>
      </el-select>
      <el-button size="small" type="primary" @click="loadData(1)">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
      <el-button size="small" type="warning" @click="drawerVisible = true">添加公告</el-button>
    </div>
    <el-table
        class="space-table"
        :data="tableData"
        :height="height"
        border
        style="width: 100%">
      <el-table-column
          prop="id"
          label="公告id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="title"
          label="标题"
          width="120">
      </el-table-column>
      <el-table-column
          prop="content"
          label="通知内容"
          width="160">
        <template v-slot="scope">
          <el-popover
              placement="bottom"
              width="200"
              :content="scope.row.content"
              trigger="click">
            <el-button type="text" slot="reference" style="width: 120px;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
              {{ scope.row.content }}
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
          prop="status"
          :formatter="statusFormatter"
          label="通知状态"
          width="80">
      </el-table-column>
      <el-table-column
      label="操作"
      width="200">
        <template v-slot="scope">
          <el-button v-if="scope.row.status === 1" type="text" size="small" @click="toInvalid(scope.row.id)">失效</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="space-table-data"
        background
        layout="total, prev, pager, next"
        :page-size="pageSize"
        hide-on-single-page
        @current-change="loadData"
        :current-page="currentPage"
        :total="total">
    </el-pagination>

    <el-drawer
        title="添加公告"
        :visible.sync="drawerVisible"
        direction="rtl">
      <div class="space-drawer">
        <el-form ref="formRule" :model="formRule" :rules="rules">
          <el-form-item label="通知标题" prop="title">
            <el-input v-model.trim="formRule.title" />
          </el-form-item>
          <el-form-item label="通知状态" prop="status">
            <el-select v-model.trim="formRule.status">
              <el-option label="生效" value="1"></el-option>
              <el-option label="失效" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="通知内容" prop="content">
            <el-input type="textarea" autosize v-model.trim="formRule.content" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('formRule')">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {addNotice, queryNotice, updateNoticeStatus} from "@/api/request";

export default {
  name: "NoticeList",
  data(){
    return {
      queryField: {
        noticeId: '',
        status: ''
      },
      tableData: [],
      height: window.innerHeight - 210,
      currentPage: 1,
      pageSize: 13,
      total: 0,
      drawerVisible: false,
      formRule:{
        title: '',
        content: '',
        status: '1'
      },
      rules: {
        title: [
          { required: true, message: '请填写通知标题', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请填写通知内容', trigger: 'blur' },
        ],
        status: [
          { required: true, message: '请选择通知状态', trigger: 'blur' },
        ]
      }
    }
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      queryNotice(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentPage = index;
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addNotice(this.formRule).then(res => {
            if(res.data.success){
              this.$message.success("操作成功");
              this.loadData(1);
              this.drawerVisible = false;
            }else {
              this.$message.error(res.data.error.message);
            }
          })
        } else {
          return false;
        }
      });
    },
    toInvalid(id){
      this.$confirm("确认作废该通知吗？",{
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        updateNoticeStatus(id).then(res => {
          if(res.data.success){
            this.$message.success("操作成功");
            this.loadData(this.currentPage);
          }else {
            this.$message.error(res.data.error.message);
          }
        })
      }).catch(() => {
      })
    },
    reset(){
      this.queryField = {
        noticeId: '',
        status: ''
      }
    },
    statusFormatter(row, column, cellValue, index){
      let rep = '';
      if(cellValue === 1){
        rep = '生效';
      }else if(cellValue === 2){
        rep = '失效';
      }
      return rep;
    }
  }
}
</script>

<style scoped>

</style>