<template>
  <div class="space-query" style="overflow: hidden;overflow-y: auto;">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <div class="form-border">
          <el-row :gutter="30">
            <el-col :span="8">
              <el-form-item label="攻略名称" prop="contentName">
                <el-input size="small" v-model="ruleForm.contentName"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏名称" prop="gameId">
                <long-range-search-game size="small" @select="handleSelect" @input="handleInput"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="30">
            <el-col :span="8">
              <el-form-item label="攻略类型" prop="contentType">
                <el-select size="small" v-model="ruleForm.contentType">
                  <el-option label="攻略" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="攻略状态" prop="status">
                <el-select size="small" v-model="ruleForm.status">
                  <el-option label="正常" value="1"></el-option>
                  <el-option label="下架" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="攻略描述">
                <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 8}" resize="none" size="small" v-model="ruleForm.contentDes"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="ruleForm.contentType !== '5'">
            <el-col :span="23">
              <el-form-item label="攻略内容">
                <tiny-edit v-if="tinyOpen" v-model="ruleForm.contentText" :height="500"></tiny-edit>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="margin: 20px auto;width: 150px">
            <el-button size="small" type="primary" @click="submitForm('ruleForm')">添加</el-button>
            <el-button size="small" @click="cancelAdd">取消</el-button>
          </div>
        </div>
      </el-form>
  </div>
</template>

<script>
import richTextEditor from '@/components/richTextEditor.vue';
import {addContent, loadGame} from "@/api/request";
import LongRangeSearchGame from "@/components/LongRangeSearchGame.vue";

export default {
  name: "contentAdd",
  components: {
    LongRangeSearchGame,
    'tinyEdit': richTextEditor
  },
  data() {
    return {
      ruleForm: {
        gameId: '',
        contentName: '',
        contentType: '1',
        contentDes: '',
        status: '',
        contentText: '' 
      },
      rules: {
        gameId: [
          { required: true, message: '请填写游戏名称', trigger: 'blur' },
        ],
        contentName: [
          { required: true, message: '请填写攻略名称', trigger: 'blur' },
        ],
        contentType: [
          { required: true, message: '请选择攻略类型', trigger: 'blur' },
        ],
        status: [
          { required: true, message: '请选择攻略状态', trigger: 'blur' },
        ],
      },
      game: '',
      restaurants: [],
      tinyOpen: true,
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.contentText = this.formatRichText(this.ruleForm.contentText);
          let params = {
            content_name: this.ruleForm.contentName,
            game_id: this.ruleForm.gameId,
            content_type: this.ruleForm.contentType,
            status: this.ruleForm.status,
            content_des: this.ruleForm.contentDes,
            content_text: this.ruleForm.contentText
          }
          addContent(params).then(res => {
            if(res.data.success){
              this.$message.success("添加成功");
              this.$router.replace('/content/contentList');
              this.$refs['ruleForm'].resetFields();
              this.game = '';
              this.ruleForm.contentDes = '';
            }else {
              this.$message.error(res.data.error.message);
            }
          })
        } else {
          return false;
        }
      });
    },
    cancelAdd(){
      this.$router.replace('/content/contentList');
      this.$refs['ruleForm'].resetFields();
      this.game = '';
      this.ruleForm.contentDes = '';
    },
    loadGame(query){
      if (query !== '') {
        let me = this;
        loadGame(query).then(res => {
          me.restaurants = [];
          res.data.data.forEach(function (e) {
            let item = {}
            item.key = e.game_id
            item.value = e.game_id
            item.label = e.game_name
            me.restaurants.push(item)
          })
        })
      }
    },
    handleSelect(item){
      this.ruleForm.gameId = item.gameId
    },
    handleInput(item){
      this.ruleForm.gameId = item.gameId
    },
    cancelReadOnly(value){
      this.$nextTick(() => {
        if (!value) {
          const {selectGame} = this.$refs;
          const input = selectGame.$el.querySelector(".el-input__inner");
          input.removeAttribute("readonly");
          this.$refs.selectGame.blur();
        }
      });
    },
    // 处理富文本展示
    formatRichText(html) {
      let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
        match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '')
        match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '')
        match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '')
        return match
      })
      newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
        match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;')
        return match
      })
      newContent = newContent.replace(/<br[^>]*\/>/gi, '')
      newContent = newContent.replace(/\<img/gi, '<img style="max-width:100% !important;height:auto;display:block;margin-top:0;margin-bottom:0;"')
      return newContent
    }
  },
  /*解决富文本切换路由无法使用的问题*/
  beforeRouteEnter(to,from,next){
    next(vm => {
      vm.tinyOpen = true;
    })
  },
  beforeRouteLeave(to,from,next){
    this.tinyOpen = false;
    next();
  }
}
</script>

<style scoped>
/deep/ .el-input,.el-select,/deep/ .el-textarea__inner {
  width: 350px;
}
</style>