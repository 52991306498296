<template>
  <div style="display: inline-block;">
    <el-autocomplete
        v-model.trim="gameName"
        :size="size"
        :fetch-suggestions="querySearch"
        placeholder="游戏名称"
        @select="handleSelect"
        @input="handleGameInput"
        style="width: 100%;"
    ></el-autocomplete>
  </div>
</template>

<script>
import {getGame} from "@/api/request";

export default {
  name: "LongRangeSearchGame",
  props: {
    game: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '',
      validator(value) {
        return ['mini', 'small', 'medium'].includes(value);
      }
    }
  },
  data(){
    return {
      gameName: this.game,
      restaurants: [],
    }
  },
  mounted() {
    this.loadGame();
  },
  methods: {
    loadGame() {
      getGame().then(res => {
        this.restaurants = [];
        res.data.data.forEach(e => {
          let item = {};
          item.value = e.gameName;
          item.gameId = e.gameId;
          this.restaurants.push(item);
        })
      })
    },
    querySearch(queryString, callback){
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      callback(results);
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleGameInput(value){
      const find = this.restaurants.find(e => e.value === this.gameName.trim());
      this.$emit('input',find);
    },
    reset(){
      this.gameName = '';
    },
    handleSelect(item){
      this.$emit('select',item);
    },
  }
}
</script>

<style scoped>

</style>