<template>
  <div>
    <div class="space-query">
      <el-input v-model="queryField.orderId" placeholder="订单id"></el-input>
      <el-input v-model="queryField.userId" placeholder="用户id"></el-input>
      <el-input v-model="queryField.goodsId" placeholder="商品id"></el-input>
      <el-input v-model="queryField.gameId" placeholder="游戏id"></el-input>
      <el-select v-model="queryField.goodsType">
        <el-option label="奖励类型" value=""></el-option>
        <el-option label="代金券" value="1"></el-option>
        <el-option label="微信红包" value="2"></el-option>
        <el-option label="实物" value="3"></el-option>
      </el-select>
      <el-select v-model="queryField.status">
        <el-option label="订单状态" value=""></el-option>
        <el-option label="待支付" value="1"></el-option>
        <el-option label="已支付" value="2"></el-option>
        <el-option label="已发货" value="3"></el-option>
      </el-select>
      <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
    </div>
    <el-table
        class="space-table"
        :data="tableData"
        :height="height"
        border
        style="width: 100%">
      <el-table-column
          prop="order_id"
          label="订单id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="user_id"
          label="购买用户id"
          width="100">
      </el-table-column>
      <el-table-column
          prop="user_name"
          label="购买用户名"
          width="120">
      </el-table-column>
      <el-table-column
          prop="good_id"
          label="商品id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="good_name"
          label="商品名称"
          width="130">
      </el-table-column>
      <el-table-column
          prop="game_id"
          label="游戏id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="game_name"
          label="游戏名"
          width="120">
      </el-table-column>
      <el-table-column
          label="商品描述"
          width="150">
        <template v-slot="scope">
          <el-popover
              placement="bottom"
              width="200"
              :content="scope.row.good_desc"
              trigger="click">
            <el-button type="text" slot="reference" style="width: 120px;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
              {{ scope.row.good_desc }}
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
          prop="good_price"
          label="商品价格"
          width="100">
      </el-table-column>
      <el-table-column
          prop="good_transfer"
          label="商品转换价值"
          width="120">
      </el-table-column>
      <el-table-column
          label="商品图片"
          width="80">
        <template v-slot="scope">
          <el-image fit="fill" :src="scope.row.good_icon" style="width: 40px;height: 40px"/>
        </template>
      </el-table-column>
      <el-table-column
          prop="goods_type"
          label="商品类型"
          :formatter="goodsTypeFormatter"
          width="80">
      </el-table-column>
      <el-table-column
          prop="status"
          label="订单状态"
          width="80">
        <template v-slot="scope">
          <span :style="{color: (statusFormatterColor(scope.row.status))}"> {{ statusFormatter(scope.row.status) }} </span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作">
        <template v-slot="scope" style="text-align: left">
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="space-table-data"
        background
        layout="total, prev, pager, next"
        :page-size="pageSize"
        hide-on-single-page
        @current-change="loadData"
        :current-page="currentPage"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {queryShopGoodsOrder} from "@/api/request";

export default {
  name: "ShopOrder",
  data() {
    return {
      queryField: {
        orderId: '',
        goodsId: '',
        goodsName: '',
        goodsType: '',
        gameId: '',
        status: ''
      },
      tableData: [],
      height: window.innerHeight - 210,
      currentPage: 1,
      pageSize: 13,
      total: 1,
    }
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      queryShopGoodsOrder(params).then(res => {
        this.tableData = res.data.list;
        this.currentPage = index;
        this.total = res.data.total;
      })
    },
    reset(){
      this.queryField = {
        orderId: '',
        userId: '',
        goodsId: '',
        goodsType: '',
        gameId: '',
        status: ''
      }
    },
    goodsTypeFormatter(row, column, cellValue){
      let rep = '';
      if(cellValue === 1){
        rep = "代金券";
      }else if (cellValue === 2){
        rep = "微信红包";
      }else if(cellValue === 3){
        rep = "实物";
      }
      return rep;
    },
    statusFormatter(cellValue){
      let rep = '';
      if(cellValue === 1){
        rep = "待支付";
      }else if (cellValue === 2){
        rep = "已支付";
      }else if (cellValue === 3){
        rep = "已发货";
      }
      return rep;
    },
    statusFormatterColor(cellValue){
      let rep = "";
      if(cellValue === 1){
        rep = "#E6A23C";
      }else if(cellValue === 2){
        rep = "#67C23A"
      }else if(cellValue === 3){
        rep = "#ac96ec"
      }
      return rep;
    },
  }
}
</script>

<style scoped>
</style>