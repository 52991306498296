import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

import Login from "@/views/login/login.vue";
import Menu from "@/views/menu/Menu.vue";
import Home from "@/views/welcome/welcome.vue";
import GameList from "@/views/game/GameList.vue";
import GameEdit from "@/views/game/GameEdit.vue";
import AdminList from "@/views/system/AdminList.vue";
import ShopGoods from "@/views/shop/ShopGoods.vue";
import ShopOrder from "@/views/shop/ShopOrder.vue";
import UserList from "@/views/user/Userlist.vue";
import GameTask from "@/views/game/GameTask.vue";
import NoticeList from "@/views/system/NoticeList.vue";
import OrgList from "@/views/system/OrgList.vue";
import OrgConfig from "@/views/system/OrgConfig.vue";
import UserAccountList from "@/views/user/UserAccountList.vue";
import ChangePassword from "@/views/system/ChangePassword.vue";
import GameConfigList from "@/views/game/GameConfigList.vue";
import GameVideo from "@/views/game/GameVideo.vue";
import OrderList from "@/views/order/OrderList.vue";
import GameSearch from "@/views/game/GameSearch.vue";
import Giftlist from "@/views/gift/giftlist.vue";
import GiftAdd from "@/views/gift/giftAdd.vue";
import GiftEdit from "@/views/gift/giftEdit.vue";
import Giftcodelist from "@/views/gift/code/giftcodelist.vue";
import Giftcodeadd from "@/views/gift/code/giftcodeadd.vue";
import GiftCodeRecord from "@/views/gift/codeRecord/giftCodeRecord.vue";
import ContentList from "@/views/content/ContentList.vue";
import ContentAdd from "@/views/content/contentAdd.vue";
import ContentEdit from "@/views/content/contentEdit.vue";
import CustomerServiceSystem from "@/views/webrtc/customerServiceSystem.vue"

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes:[
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                title: '登录'
            }
        },
        {
            path: '/',
            name: "Menu",
            component: Menu,
            children: [
                {
                    path: '/home',
                    name: 'Home',
                    component: Home,
                    meta: {
                        title: '首页'
                    }
                },
                {
                    path: `/changePassword`,
                    name: 'ChangePassword',
                    component: ChangePassword,
                    meta: {
                        title: '修改密码'
                    }
                },
                {
                    path: `/game/list`,
                    name: 'GameList',
                    component: GameList,
                    meta: {
                        title: '游戏列表'
                    }
                },
                {
                    path: `/game/edit/*`,
                    name: "GameEdit",
                    component: GameEdit,
                    meta: {
                        title: "编辑游戏"
                    }
                },
                {
                    path: `/game/task`,
                    name: 'GameTask',
                    component: GameTask,
                    meta: {
                        title: "游戏任务"
                    }
                },
                {
                    path: `/game/config`,
                    name: 'GameConfig',
                    component: GameConfigList,
                    meta: {
                        title: "游戏配置"
                    }
                },
                {
                    path: `/game/video`,
                    name: 'GameVideo',
                    component: GameVideo,
                    meta: {
                        title: "游戏视频"
                    }
                },
                {
                    path: `/system/admin`,
                    name: 'AdminList',
                    component: AdminList,
                    meta: {
                        title: "管理员列表"
                    }
                },
                {
                    path: `/shop/goods`,
                    name: 'ShopGoods',
                    component: ShopGoods,
                    meta: {
                        title: "商城商品列表"
                    }
                },
                {
                    path: `/shop/order`,
                    name: 'ShopOrder',
                    component: ShopOrder,
                    meta: {
                        title: "商城订单列表"
                    }
                },
                {
                    path: `/user/list`,
                    name: 'UserList',
                    component: UserList,
                    meta: {
                        title: "用户列表"
                    }
                },
                {
                  path: `/user/account`,
                  name: 'UserAccountList',
                  component: UserAccountList,
                  meta: {
                      title: "用户子账号"
                  }  
                },
                {
                    path: '/system/notice',
                    name: 'SystemNotice',
                    component: NoticeList,
                    meta: {
                        title: "公告列表"
                    }
                },
                {
                    path: '/org',
                    name: 'OrgList',
                    component: OrgList,
                    meta: {
                        title: "家族列表"
                    }
                },
                {
                    path: `/org/config`,
                    name: 'OrgConfig',
                    component: OrgConfig,
                    meta: {
                        title: "家族配置"
                    }
                },
                {
                    path: `/order/list`,
                    name: 'OrderList',
                    component: OrderList,
                    meta: {
                        title: "订单列表"
                    }
                },
                {
                    path: `/game/search`,
                    name: 'GameSearch',
                    component: GameSearch,
                    meta: {
                        title: "游戏搜索"
                    }
                },
                {
                    path: `/gift/list`,
                    name: 'GiftList',
                    component: Giftlist,
                    meta: {
                        title: "礼包列表"
                    }
                },
                {
                    path: `/gift/add`,
                    name: 'GiftAdd',
                    component: GiftAdd,
                    meta: {
                        title: "添加礼包"
                    }
                },
                {
                    path: `/gift/edit/*`,
                    name: 'GiftEdit',
                    component: GiftEdit,
                    meta: {
                        title: "编辑礼包"
                    }
                },
                {
                    path: `/gift/code`,
                    name: 'GiftCode',
                    component: Giftcodelist,
                    meta: {
                        title: "礼包码列表"
                    }
                },
                {
                    path: `/gift/code/add`,
                    name: 'GiftCodeAdd',
                    component: Giftcodeadd,
                    meta: {
                        title: "添加礼包码"
                    }
                },
                {
                    path: `/gift/record`,
                    name: 'GiftRecord',
                    component: GiftCodeRecord,
                    meta: {
                        title: "礼包码记录"
                    }
                },
                {
                    path: `/content/contentList`,
                    name: 'ContentList',
                    component: ContentList,
                    meta: {
                        title: "游戏攻略"
                    }
                },
                {
                    path: `/content/add`,
                    name: 'ContentAdd',
                    component: ContentAdd,
                    meta: {
                        title: "添加游戏攻略"
                    }
                },
                {
                    path: `/content/edit/*`,
                    name: 'ContentEdit',
                    component: ContentEdit,
                    meta: {
                        title: "编辑游戏攻略"
                    }
                },
                {
                    path: `/customerServiceSystem`,
                    name: 'CustomerServiceSystem',
                    component: CustomerServiceSystem,
                    meta: {
                        title: "客服系统"
                    }
                }
            ]
        }
    ]
})